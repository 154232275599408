
import { defineComponent } from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { toast, emitter } from '../../main';
export default defineComponent({
	props: ['allCourses'],
	data(): any {
		return {
			time: moment().format('DD-MM-YYYY'),
			isHorizonatalView: false,
			completedBy: '',
			startedAt :''
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		Images(payload: any) {
			console.log(payload.courseImageUrl);
			if (payload.courseImageUrl != '' && payload.courseImageUrl != undefined) {
				return payload.courseImageUrl;
			} else {
				return process.env.VUE_APP_COMMON_IMAGE;
			}
		},
		async getAllModules() {
			console.log("sss")
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.allCourses._id}/userActivity`)
				.then((a: any) => {
					this.userActivity = a.data[0];
					if(this.userActivity.modules){
						let module = this.userActivity.modules.length - 1;
					let date = new Date(this.userActivity.modules[module].updatedAt);
					this.completedBy = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
					let updateDate = new Date(this.userActivity.modules[0].updatedAt);
							this.startedAt = updateDate.getDate() + '-' + (updateDate.getMonth() + 1) + '-' + updateDate.getFullYear();
					}
					
				})

				.catch((error: any) => {
					toast.error("ddd", {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		downloadCerificate: function (userInfo: any): any {
			return `${process.env.VUE_APP_TRAINING_API_URL}/user/${userInfo._id}/course/${this.allCourses._id}/certificate`;
		}
	},
	async mounted() {
		await this.getAllModules();
	}
});
