
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import spinner from '../../components/spinner.vue';
import { toast } from '../../main';
import { requiredIf, requiredUnless } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
export default defineComponent({
	components: {
		spinner
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		disableStatus: function (): any {
			let disableStatusCondition;
			console.log('Dynamicity');
			if (this.currentScreen._id) {
				console.log('this.currentScreen', this.currentScreen);
				let { isActive,toggleSourceInput, ...rest } = this.currentScreen;
				console.log('rest', rest);
				console.log('this.screens[this.modIndex]', this.dummy[this.modIndex]);
				// console.log("this.dummyObj",this.dummyObj)
				if (_.isEqual(rest, this.dummy[this.modIndex])) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			}
			return disableStatusCondition;
		}
	},

	data(): any {
		return {
			v$: useVuelidate(),
			screens: [],
			showvalidation: false,
			showvalidationforaccordions: false,
			spinnerLoading: false,
			screenNameValidation: false,
			totalScreens: [],
			optionListIndex: '',
			updateButton: false,
			checkIds: [],
			course: '',
			moduleName: '',
			imageUrl: '',
			videoUrl: '',
			videoScreen: false,
			currentScreen: {},
			modIndex: '',
			dummy: [],

			fileStatus: true,

			validCode:'',
			fileName:'',

			fileNameAudio:'',

			enableUpload:true,

			audioUrl:'',
			originalObject:null,
			originalIndex:null,
			dummyScreens:[],
		};
	},

	validations() {
		return {
			currentScreen: {
				screenName: { required },
				screenType: { required },
				headerLabel: {
					required: requiredUnless(() => {
						return this.videoScreen;
					})
				},
				headerData: {
					required: requiredUnless(() => {
						return this.videoScreen;
					})
				},
				videoUrl: {
					required: requiredIf(() => {
						return this.videoScreen;
					})
				}
			}
		};
	},
	methods: {
		screenSelect(singlescreen: any, index: any,status:any) {
			console.log('selctScreen....index', index);
			this.screens.map((screen:any,screenIndex:any)=>{
				if(screenIndex == index){
					this.screens[index].isActive = !this.screens[index].isActive;
					if(this.screens[index].isActive == true){
						let presentDummyScreen = this.dummyScreens.find((obj:any)=>{return singlescreen._id == obj._id})
	
						this.originalObject = {...presentDummyScreen}
						this.originalObject.isActive = true;
						this.originalIndex = index;
					}
				}else{
					this.screens[screenIndex].isActive = false;
				}
			})
		    // if(status){
			// 	// singlescreen.isActive = !singlescreen.isActive;
			// }
			// else{
			// 	singlescreen.isActive = true;
			// }
			// this.singlescreen._id ? this.enableUpload = false:'';
			this.videoUrl = '';
			this.imageUrl = '';
			this.videoScreen = false;
			this.modIndex = index;
			this.currentScreen = this.screens[this.modIndex];
			this.currentScreen._id ? this.isScreenselected():'';
			
			// console.log("changethis.videoScreen",this.videoScreen);
		},
		isScreenselected(){
			if(!this.screens[this.modIndex].screenType){
				this.enableUpload = true;
				toast.info('Select atleast one Screen Type', {
						timeout: 1500,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
			}else{
				this.enableUpload = false;
			}

		},
		updateIndex(index: any) {
			console.log('index', index);
			this.modIndex = index;
			this.currentScreen = this.screens[this.modIndex];
		},
		async refreshScreens(moduleIndex: any) {
			this.fileStatus = true;
			await this.getallScreens();
			await this.screenSelect(this.screens[moduleIndex], moduleIndex);
			this.fileName='';
			this.fileNameAudio = '';
		},
		changedInputControlValue(screen: any, index: any) {
			this.videoScreen = screen.screenType == 'VideoScreen';
			this.enableUpload = false;
		},
		audioCheckFile(index:any){
			let file = [];
			this.fileNameAudio = '';
			this.currentScreen.screenType !== 'TabScreen' ? (file = this.$refs[`elementAudio${index}`].files[0]) : (file = this.$refs.fileInputAudio.files[0]);
			console.log("file",file);
			if(file != undefined){
				if (file.type == 'audio/mpeg') {
				let size = Number((file.size / 1048576).toFixed(2));
					if (size > 80) {
						toast.error('Audio should be atmost 80 MB', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.resetAudio()
					} 
					else {
						this.fileNameAudio = file.name;
						this.fileStatus = false;
					}
			} else {
					toast.error('Supported only mp3', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetAudio()
			}
			}

		},
		resetAudio(){
			if(this.currentScreen.screenType != 'TabScreen'){
				if(this.$refs[`elementAudio${this.modIndex}`].value){
						this.$refs[`elementAudio${this.modIndex}`].value='';
							
				}
			}
			else{
				if(this.$refs.fileInput.value){
					this.$refs.fileInput.value='';
				}
			}	
		},
		checkFile(index: any) {
			let file = [];
			this.fileName = '';
			this.currentScreen.screenType !== 'TabScreen' ? (file = this.$refs[`element${index}`].files[0]) : (file = this.$refs.fileInput.files[0]);
			console.log("file",file);
			
			if (file != undefined) {
				if (this.currentScreen.screenType == 'VideoScreen') {
					this.videoCheck(file);
				} else {
					this.imgCheck(file);
				}
			}
			// }
			console.log('file', file);
		},
		imgCheck(file: any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Image should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} 
				else {
					this.fileName = file.name;
					this.fileStatus = false;
				}
			} else {
				toast.error('Supported only jpg, jpeg, png, svg', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads()
			}
		},
		videoCheck(file: any) {
			if (file.type == 'video/mp4') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Video should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} else {
					this.fileName = file.name;
					this.fileStatus = false;
				}
			} else {
				toast.error('Supported only mp4', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				   this.resetFileUploads()
			}
		},
		resetFileUploads(){
			if(this.currentScreen.screenType != 'TabScreen'){
				if(this.$refs[`element${this.modIndex}`].value){
					this.$refs[`element${this.modIndex}`].value='';
						
				}	
			}
			else{
				if(this.$refs.fileInput.value){
					this.$refs.fileInput.value='';
				}
			}	
		},
		resetData(screen: any,index:any) {
			if(screen._id){
				this.screens[this.modIndex] = JSON.parse(JSON.stringify(this.dummy[this.modIndex]));
				if(this.fileName){
				    this.resetFileUploads();
					this.fileName='';
					this.fileStatus = true;
				}
				if(this.fileNameAudio){
					this.resetFileUploads();
					this.fileNameAudio ='';
					this.fileStatus = true;
				}
				this.v$.$reset();
			}
			else{
				this.resetFileUploads()
				this.fileName='';
				this.fileNameAudio ='';
				this.v$.$reset();
			(screen.screenName = 'Screen'),
				(screen.screenType = ''),
				(screen.isActive = true),
				(screen.order = ''),
				(screen.backgroundimage = ''),
				(screen.color = 'red'),
				(screen.headerLabel = ''),
				(screen.headerData = ''),
				(screen.optionsLabel = ''),
				(screen.optionsData = ''),
				(screen.options = []),
				(screen.accordions = []),
				(screen.textbullets = []),
				(screen.tabs = []),
				(screen.correctAnswer = false),
				(screen.answerVisibility = true),
				(screen.validationScreenName = false),
				(screen.isShowPreview = false),
				(screen.toggleSourceInput = false),
				(screen.audioUrl = ''),
				(screen.imageUrl = ''),
				(screen.videoUrl = ''),
				(screen.isScreenActive = true);
				this.enableUpload = true;

			}
				this.screenSelect(this.screens[this.modIndex], this.modIndex);
				console.log("RESETthis.currentScreen",this.currentScreen)
		},
		addScreen(): any {
			this.enableUpload = true;
			this.screens.push({
				screenName: 'Screen',
				screenType: '',
				isActive: false,
				order: '',
				backgroundimage: '',
				color: 'red',
				headerLabel: '',
				headerData: '',
				optionsLabel: '',
				optionsData: '',
				options: [],
				accordions: [],
				textbullets: [],
				tabs: [],
				correctAnswer: false,
				answerVisibility: true,
				validationScreenName: false,
				isShowPreview: false,
				toggleSourceInput: false,
				audioUrl: '',
				imageUrl: '',
				videoUrl: '',
				isScreenActive: false
			});
		},
		preview(screenSelection: any, screenId: any): any {
			if (screenSelection == 'TextBoxScreen') {
				console.log(window.location.origin);
				window.open(`${window.location.origin}/admin/template/text/${screenId}`, '_blank');
			} else if (screenSelection == 'RadioScreen') {
				window.open(`${window.location.origin}/admin/template/radio/${screenId}`, '_blank');
			} else if (screenSelection == 'CheckBoxScreen') {
				window.open(`${window.location.origin}/admin/template/checkbox/${screenId}`, '_blank');
			} else if (screenSelection == 'VerticallyAccordionScreen') {
				window.open(`${window.location.origin}/admin/template/vaccordion/${screenId}`, '_blank');
			} else if (screenSelection == 'HorizantallyAccordionScreen') {
				window.open(`${window.location.origin}/admin/template/haccordion/${screenId}`, '_blank');
			} else if (screenSelection == 'TabScreen') {
				window.open(`${window.location.origin}/admin/template/tab/${screenId}`, '_blank');
			} else if (screenSelection == 'TextWithBulletPointsScreen') {
				window.open(`${window.location.origin}/admin/template/textbullet/${screenId}`, '_blank');
			} else if (screenSelection == 'VideoScreen') {
				window.open(`${window.location.origin}/admin/template/video/${screenId}`, '_blank');
			}
		},

		addOptions(moduleIndex: any, optionValue: string) {
			if (optionValue != '') {
				if (this.screens[moduleIndex].screenType == 'RadioScreen') {
					this.saveRadiooptions(moduleIndex);
				} else {
					this.saveCheckBoxOptions(moduleIndex);
				}
			}
		},

		onDelete(optionIndex: any, moduleIndex: any) {
			if (this.screens[moduleIndex].screenType == 'RadioScreen') {
				this.onradioDelete(optionIndex, moduleIndex);
			} else {
				this.onCheckBoxOptionsDelete(optionIndex, moduleIndex);
			}
		},
		onCheckBoxOptionsDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].options.splice(optionIndex, 1);
		},
		backtocourseEdit() {
			// console.log("Ssss",this.$route.params)
			this.$router.push({ name: 'edit-course', params: { id: this.$route.params.courseId } });
		},
		saveCheckBoxOptions(moduleIndex: any): any {
			this.screens[moduleIndex].options.push({
				label: this.screens[moduleIndex].optionsData,
				isCorrect: this.screens[moduleIndex].correctAnswer || false
			});
			(this.screens[moduleIndex].correctAnswer = ''), (this.screens[moduleIndex].optionsData = '');
		},
		async addOrUpdateScreen(moduleIndex: any) {
			this.screens[moduleIndex]['isActive'] = true;
			this.screens[moduleIndex].isShowPreview = true;
			let errorCondition = this.v$.currentScreen.$errors;
			// stop here if form is invalid
			if (errorCondition.length == 0) {
				this.v$.$reset();
				var finalpayload;
				// delete this.screens[moduleIndex]['isActive'];
				let date = new Date();
				finalpayload = {
					screens: [
						{
							createdBy: this.userInfo.sub,
							updatedBy: this.userInfo.sub,
							createdAt: date,
							updatedAt: date,
							screenName: this.screens[moduleIndex].screenName,
							screenType: this.screens[moduleIndex].screenType,
							order: this.screens[moduleIndex].order,
							screenStyles: {
								backgroundimage: this.screens[moduleIndex].backgroundimage,
								color: this.screens[moduleIndex].color
							},
							data: {
								headerLabel: this.screens[moduleIndex].headerLabel,
								headerData: this.screens[moduleIndex].headerData,
								optionsLabel: this.screens[moduleIndex].optionsLabel,
								optionsData: this.screens[moduleIndex].optionsData,
								options: this.screens[moduleIndex].options,
								accordions: this.screens[moduleIndex].accordions,
								textbullets: this.screens[moduleIndex].textbullets,
								tabs: this.screens[moduleIndex].tabs,
								imageUrl: this.screens[moduleIndex].imageUrl,
								audioUrl: this.screens[moduleIndex].audioUrl,
								videoUrl: this.screens[moduleIndex].videoUrl,
								isScreenActive: this.screens[moduleIndex].isScreenActive
							},
							_id: this.screens[moduleIndex]._id
						}
					]
				};
				if (this.screens[moduleIndex]._id == undefined) {
					this.saveScreens(finalpayload, moduleIndex);
				} else {
					this.updateScreen(finalpayload, this.screens[moduleIndex]._id);
				}
			}
		},
		uniqueScreenName(moduleIndex:any){
            this.screens[moduleIndex].validationScreenName = false;
            this.validCode = this.screens[moduleIndex].screenName;
			this.currentScreen.screenType == 'VideoScreen'?(this.currentScreen.videoUrl ==''? (this.currentScreen.videoUrl = 'exist',this.videoScreen = true): this.videoScreen = true):'';
            const uniqueValues = new Set(this.screens.map((v: any) => v.screenName));
            if (uniqueValues.size < this.screens.length) {
                this.screens[moduleIndex].validationScreenName = true;
                return false
            }
			else{
				return true;
			}
        },
		async audioUpload(audioFile:any,moduleIndex:any){
			 if (audioFile.type == 'audio/mpeg') {
				console.log("Inside Audio")
				this.currentScreen.screenType !== 'TabScreen'? await this.sendData(audioFile):'';
				this.screens[moduleIndex].audioUrl = this.audioUrl;
				this.addOrUpdateScreen(moduleIndex);
			} 
		},
		async createPayload(moduleIndex: any) {
			this.currentScreen = this.screens[moduleIndex];
			this.v$.$touch();
			let unique = this.uniqueScreenName(moduleIndex);
			console.log('bool',unique)
			if (!this.v$.$invalid && unique) {
				let file = [];
				let audioFile = [];
				if(this.currentScreen.screenType !== 'VideoScreen')
				this.currentScreen.screenType !== 'TabScreen' ? (audioFile = this.$refs[`elementAudio${moduleIndex}`].files[0]) : (audioFile = this.$refs.fileInputAudio.files[0]);
				if (this.currentScreen.screenType !== 'VerticallyAccordionScreen' && this.currentScreen.screenType !== 'HorizantallyAccordionScreen') {
					this.currentScreen.screenType !== 'TabScreen' ? (file = this.$refs[`element${moduleIndex}`].files[0]) : (file = this.$refs.fileInput.files[0]);
					if (file) {
						if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
							console.log('imageData', file);
							await this.sendData(file);
							this.screens[moduleIndex].imageUrl = this.imageUrl;
							audioFile != undefined ? '': this.addOrUpdateScreen(moduleIndex);
						} else if (file.type == 'video/mp4') {
							await this.sendData(file);
							this.screens[moduleIndex].videoUrl = this.videoUrl;
							this.$refs.player.load();
							this.addOrUpdateScreen(moduleIndex);
						} 
					} 
					if(audioFile && !this.videoScreen){
						await this.audioUpload(audioFile,moduleIndex)
					}
					else if(this.videoScreen && (file == undefined && this.currentScreen.videoUrl =='exist' ) ){
                        this.currentScreen.videoUrl ='';
                            toast.error('Upload Video', {
                            timeout: 1500,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                            });
                    }
					else if(file == undefined && (audioFile == undefined || audioFile)) {
						this.addOrUpdateScreen(moduleIndex);
					}
				} 
				else  {
					this.fileNameAudio ? await this.audioUpload(audioFile,moduleIndex):this.addOrUpdateScreen(moduleIndex);
				}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			// this.screens[moduleIndex].isShowPreview = true;
		},

		async uploadImage(data: any) {
			console.log("data",data)
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		async sendData(data: any) {
			this.spinnerLoading = true;
			// console.log("$refs.fileInput.files[0]",this.$refs.fileInput.files[0])
			const file = await this.uploadImage(data);
			let fileExtension = data.name.split('.').pop();
			let id = uuidv4();
			let findId = this.checkIds.filter((x: any) => x === id);
			if (findId.length > 0) {
				id = uuidv4();
			}
			var payload;
			// if (!this.videoScreen) {
			// 	payload = { fileData: file, name: `${id}.${fileExtension}` };
			// } else {
				payload = { fileData: file, name: `${id}.${fileExtension}` };
			// }
			console.log('payload', payload);
			try {
				await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
					//.post(`http://localhost:4030/services/filemanagement/bucket/${process.env.VUE_APP_TRAINING_API_PATH}/file/upload`, payload)
					.then((response: any) => {
						if (response.status == 200) {
							console.log('ISUE', response.data);
							this.videoScreen == true ? (this.videoUrl = response.data) : (fileExtension == 'mp3' ? (this.audioUrl = response.data):(this.imageUrl = response.data));
							
							console.log("this.audioUrl",this.audioUrl) 

							this.spinnerLoading = false;
						} else {
							console.log('response status', response.status);
						}
					});
			} catch (err) {
				console.error(err);
			}
		},

		async saveScreens(finalpayload: any, moduleIndex: any) {
			const toast = useToast();
			// this.spinnerLoading = true;
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/add`, finalpayload)
				.then((response: any) => {
					this.screens[moduleIndex]._id = response.data.updatedScreenId[0];
					this.refreshScreens(moduleIndex);
					toast.info('Screen Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					console.log('ee', error);
					// this.spinnerLoading = false;
					toast.error('Screen not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onradioDelete(optionIndex: any, moduleIndex: any) {
			if (this.screens[moduleIndex].options[optionIndex].isCorrect == true) {
				this.screens[moduleIndex].answerVisibility = true;
			}
			this.screens[moduleIndex].options.splice(optionIndex, 1);
		},

		saveRadiooptions(moduleIndex: any): any {
			var visibility = this.screens[moduleIndex].correctAnswer;
			if (visibility == true) {
				this.screens[moduleIndex].answerVisibility = false;
			}
			this.screens[moduleIndex].options.push({
				label: this.screens[moduleIndex].optionsData,
				isCorrect: this.screens[moduleIndex].correctAnswer || false
			});
			(this.screens[moduleIndex].correctAnswer = ''), (this.screens[moduleIndex].optionsData = '');
		},

		saveVerticallyChildAccordionData(moduleIndex: any) {
			if(this.screens[moduleIndex].accordions.label && this.screens[moduleIndex].accordions.text){
				this.screens[moduleIndex].accordions.push({
					label: this.screens[moduleIndex].accordions.label,
					text: this.screens[moduleIndex].accordions.text,
					isActive: false
				});
				(this.updateButton = false), (this.screens[moduleIndex].accordions.label = ''), (this.screens[moduleIndex].accordions.text = '');
			}
		},

		async saveTabData(moduleIndex: any) {
			if (this.screens[moduleIndex].tabs.label && this.screens[moduleIndex].tabs.text) {
				if (this.$refs.fileInput.files[0] != undefined) {
						await this.sendData(this.$refs.fileInput.files[0]);
					
				}
				if (this.$refs.fileInputAudio.files[0] != undefined) {
						await this.sendData(this.$refs.fileInputAudio.files[0]);
					
				}
				 else {
					this.imageUrl = this.screens[moduleIndex].imageUrl;
				}
				this.screens[moduleIndex].tabs.label;
				this.screens[moduleIndex].tabs.push({
					label: this.screens[moduleIndex].tabs.label,
					text: this.screens[moduleIndex].tabs.text,
					imageUrl: this.imageUrl,
					audioUrl: this.audioUrl,
					isActive: false
				});
				(this.updateButton = false), (this.screens[moduleIndex].tabs.audioUrl = ''), (this.screens[moduleIndex].tabs.label = ''), (this.screens[moduleIndex].tabs.text = ''), (this.screens[moduleIndex].tabs.imageUrl = '');
				this.fileName = '';
				this.fileNameAudio = '';
			}
		},

		async updateTabData(moduleIndex: any) {
			if (this.$refs.fileInput.files[0] != undefined) {
				console.log('if');
				await this.sendData(this.$refs.fileInput.files[0]);
			} else {
				console.log('else');
				this.imageUrl = this.screens[moduleIndex].tabs.imageUrl;
			}
			console.log('imageUrl', this.imageUrl);
			(this.screens[moduleIndex].tabs[this.optionListIndex].label = this.screens[moduleIndex].tabs.label), (this.screens[moduleIndex].tabs[this.optionListIndex].text = this.screens[moduleIndex].tabs.text);
			this.screens[moduleIndex].tabs[this.optionListIndex].imageUrl = this.imageUrl;
			this.screens[moduleIndex].tabs[this.optionListIndex].audioUrl = this.screens[moduleIndex].tabs.audioUrl;
			this.fileName = '';
			this.fileNameAudio = '';
			this.optionListIndex = '';
			this.screens[moduleIndex].tabs.label = '';
			this.screens[moduleIndex].tabs.text = '';
			this.screens[moduleIndex].tabs.imageUrl = '';
			this.screens[moduleIndex].tabs.audioUrl = '';
			this.updateButton = false;
		},
		onTabEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			console.log('this.screens[moduleIndex].tabs', this.screens[moduleIndex].tabs);
			this.screens[moduleIndex].tabs.label = this.screens[moduleIndex].tabs[optionIndex].label;
			this.screens[moduleIndex].tabs.text = this.screens[moduleIndex].tabs[optionIndex].text;
			this.screens[moduleIndex].tabs.imageUrl = this.screens[moduleIndex].tabs[optionIndex].imageUrl;
			this.screens[moduleIndex].tabs.audioUrl = this.screens[moduleIndex].tabs[optionIndex].audioUrl;
		},
		onTabDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].tabs.splice(optionIndex, 1);
		},
		onVerticallyChildAccordionDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].accordions.splice(optionIndex, 1);
		},
		updateVerticallyChildAccordionData(moduleIndex: any) {
			(this.screens[moduleIndex].accordions[this.optionListIndex].label = this.screens[moduleIndex].accordions.label), (this.screens[moduleIndex].accordions[this.optionListIndex].text = this.screens[moduleIndex].accordions.text);

			this.optionListIndex = '';
			this.screens[moduleIndex].accordions.label = '';
			this.screens[moduleIndex].accordions.text = '';
			this.updateButton = false;
		},
		updatetextbulletData(moduleIndex: any) {
			this.screens[moduleIndex].textbullets[this.optionListIndex].text = this.screens[moduleIndex].textbullets.text;
			this.optionListIndex = '';
			this.screens[moduleIndex].textbullets.text = '';
			this.updateButton = false;
		},
		savetextbulletData(moduleIndex: any) {
			if(this.screens[moduleIndex].textbullets.text){
			this.screens[moduleIndex].textbullets.push({
				text: this.screens[moduleIndex].textbullets.text,
				isActive: false
			});
			(this.updateButton = false), (this.screens[moduleIndex].textbullets.text = '');
			}
		},
		ontextbulletDelete(optionIndex: any, moduleIndex: any) {
			console.log('sssss');
			this.screens[moduleIndex].textbullets.splice(optionIndex, 1);
		},
		ontextbulletEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			this.screens[moduleIndex].textbullets.text = this.screens[moduleIndex].textbullets[optionIndex].text;
		},
		onVerticallyChildAccordionEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			this.screens[moduleIndex].accordions.label = this.screens[moduleIndex].accordions[optionIndex].label;
			this.screens[moduleIndex].accordions.text = this.screens[moduleIndex].accordions[optionIndex].text;
		},

		async getallScreens() {
			this.totalScreens = [];
			this.screens = [];
			this.dummyScreens = [];
			const toast = useToast();
			this.spinnerLoading = true;
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/getAll`).then((res: any) => {
				// this.spinnerLoading = false;
				this.totalScreens = res.data;

				for (var i = 0; i < this.totalScreens.length; i++) {
					
					let a = {
						screenName: this.totalScreens[i].screenName,
						screenType: this.totalScreens[i].screenType,
						order: this.totalScreens[i].order,
						backgroundimage: this.totalScreens[i].screenStyles.backgroundimage,
						color: this.totalScreens[i].screenStyles.color,
						headerLabel: this.totalScreens[i].data.headerLabel,
						headerData: this.totalScreens[i].data.headerData,
						optionsLabel: this.totalScreens[i].data.optionsLabel,
						optionsData: this.totalScreens[i].data.optionsData,
						options: this.totalScreens[i].data.options,
						accordions: this.totalScreens[i].data.accordions,
						tabs: this.totalScreens[i].data.tabs,
						textbullets: this.totalScreens[i].data.textbullets,
						audioUrl: this.totalScreens[i].data.audioUrl,
						imageUrl: this.totalScreens[i].data.imageUrl,
						videoUrl: this.totalScreens[i].data.videoUrl,
						isScreenActive: this.totalScreens[i].data.isScreenActive,
						_id: this.totalScreens[i]._id
					}
					this.screens.push(a);
					this.dummyScreens.push({...a})
					// for(let key in this.screens[i]){
					// 	if(key == 'isScreenActive'){break;}
					// }
					if (!Object.keys(this.totalScreens[i].data).includes('isScreenActive')) {
						this.screens[i]['isScreenActive'] = true;
					}

					// console.log("!Object.keys(this.screens[i]).includes('isScreenActive'",Object.keys(this.screens[i]).includes('isScreenActive'));
				}
			});
			this.dummy = JSON.parse(JSON.stringify(this.screens));
			this.spinnerLoading = false;
		},
		async updateScreen(singlescreen: any, singlescreenId: any) {
			delete singlescreen.screens[0]._id;
			var screenId = singlescreenId;
			singlescreen.screens[0].updatedBy = this.userInfo.sub;
			singlescreen.screens[0].updatedAt = new Date();
			const toast = useToast();
			this.spinnerLoading = true;
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screen/${screenId}/update`, singlescreen.screens[0])
				.then((res: any) => {
					this.spinnerLoading = false;
					toast.info('Screen updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					// this.refreshScreens(this.modIndex);
					
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				
			this.fileStatus = true;
			await this.getallScreens();
			await this.screenSelect(this.screens[this.modIndex], this.modIndex);
			this.fileName='';
			this.fileNameAudio='';
			console.log('this.totalScreens',this.totalScreens)
			let updateModuleCondition = this.totalScreens.every((obj:any)=>{
				return obj.data.isScreenActive == false;
			});
			if(updateModuleCondition){
				console.log('updatemodule To DEactive')
				console.log('do inactive')
				await this.updateModule(this.moduleInformation,false)
			}else{
				console.log('dont update')
				console.log('do active')
				await this.updateModule(this.moduleInformation,true)
			}
		},
		async getModules() {
			this.spinnerLoading = true;
			await this.$http
			.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/get`)
			.then((res: any) => {
				this.spinnerLoading = false;
				this.moduleInformation=res.data[0].modules[0];
				console.log("moduleInformationmoduleInformation",this.moduleInformation)
				
			})
			.catch((error: any) => {
				this.spinnerLoading = false;
				toast.error(error, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});

        },
		async updateModule( singlemodule: any,boolean:any) {
            this.spinnerLoading = true;
            var moduleid = singlemodule._id;
            singlemodule.moduleToggle = true;
            delete singlemodule._id;
            // delete singlemodule.moduleToggle;
            delete singlemodule.errorClass;
            singlemodule.updatedBy = this.userInfo.sub;
            singlemodule.imageUrl=this.imageUrl
            singlemodule.updatedAt = new Date();
            singlemodule.isModuleActive=boolean;
			delete singlemodule.questionaire;
            await this.$http
                .post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/update`, singlemodule)
                .then((response: any) => {
                    this.spinnerLoading = false;
                    // toast.info('Module Updated', {
                    //  timeout: 1000,
                    //  closeOnClick: true,
                    //  closeButton: 'button',
                    //  icon: true
                    // });
                    // this.getAllModules();
                })
                .catch((error: any) => {
                    // this.spinnerLoading = false;
                    // toast.error('Module not Updated', {
                    //  timeout: 1000,
                    //  closeOnClick: true,
                    //  closeButton: 'button',
                    //  icon: true
                    // });
                });
        },
		async getSingleCourse() {
			console.log('ss');
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`)
				.then((res: any) => {
					this.course = res.data;
					console.log('sss', this.course);
					this.course.forEach((course: any) => {
						this.moduleName = course.modules.filter((module: any) => module._id == this.$route.params.moduleId);
					});
				})
				.catch((error: any) => {
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			console.log('this.screens', this.screens);
			this.dummy = JSON.parse(JSON.stringify(this.screens));
			console.log(',this.dummythis.dummy', this.dummy);
			this.spinnerLoading = false;
		},
		async getallImagesfromS3() {
			try {
				await this.$http
					.get(`${process.env.VUE_APP_FILE_API_URL}/bucket/files/getall`)
					//.get(`http://localhost:4030/services/filemanagement/bucket/files/getall`)
					.then((response: any) => {
						if (response.status == 200) {
							for (var i = 0; i < response.data.Contents.length; i++) {
								let ids = response.data.Contents[i].Key.substring(0, response.data.Contents[i].Key.lastIndexOf('.'));
								this.checkIds.push(ids);
							}
						}
					});
			} catch (err) {
				console.error(err);
			}
		}
	},
	async mounted() {
		this.getallImagesfromS3()
		await this.getModules()
		console.log("ssslog")
		this.pagetype = this.$route.fullPath.includes('update') ? 'update' : 'edit';
		await this.getallScreens();
		await this.getSingleCourse();
	}
});
