
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	data() {
		return {
			isSidebarToggle: true,
			appsVisible: false,
			isActive: false,
			dashboard: false,
			hover:false,
			show:false,
			courseManagement: false,
			courses: false,
			selectedOptionName: '',
			passingRouteName:'',
			menus: [
				{
					optionName: 'dashboard',
					optionRoute: 'dashboard',
					defaultoptionImage: require('@/assets/dashboardDefault.svg'),
					colouredoptionImage: require('@/assets/dashboardColoured.svg'),
					displayOptionName: 'Dashboard',
					userRole:10207,
				},
				{
					optionName: 'course-management',
					defaultoptionImage: require('@/assets/coursemanagementDefault.svg'),
					colouredoptionImage: require('@/assets/coursemanagementColoured.svg'),
					optionRoute: 'course-management',
					displayOptionName: 'Manage Courses',
					userRole:10207,
					
				},
				{
					optionName: 'user-dashboard',
					defaultoptionImage: require('@/assets/coursesDefault.svg'),
					colouredoptionImage: require('@/assets/coursesColoured.svg'),
					optionRoute: 'user-dashboard',
					displayOptionName: 'My Courses',
				
					
					
				}
			]
		};
	},

	computed: {
		...mapGetters({ userInfo: 'userInfo',toggleState:'toggle' }),
		currentRouteName() {
            return this.$route.name;
        },
	},
	mounted() {
		if(this.$route.path.includes('dashboard')){
            this.passingRouteName = 'dashboard';
        }else if(this.$route.path.includes('manage')){
            this.passingRouteName = 'course-management'
        }else if(this.$route.path.includes('courses')||this.$route.path.includes('course')){
            this.passingRouteName = 'user-dashboard'
        }
        this.selectedOptionName = this.passingRouteName;
		console.log("logged")
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
		emitter.on('apps-sidemenubar', (isToggle: any) => {
			this.appsVisible = isToggle;
		});
		emitter.on('apps', (isToggle: any) => {
			this.appsVisible = isToggle;
		});
		this.isSidebarToggle=this.toggleState
	},
	methods: {
		navigate(name:any, routerOption:any) {	
			this.$store.dispatch('selectedToggle',this.isSidebarToggle);
			this.$store.dispatch('selectedRoute', name);
			this.$router.push({ name: routerOption });
		},
		triggerNavToSettings() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
		},
		// triggerNav() {
		// 	window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_MONITOR_WEBAPP_URL}`;
		// },
		triggerNav(app:string) {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_WORKFLOW_WEBAPP_URL}`;
		},
		triggerNavToAdminSettings() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
		},
		toggleSideMenuBar() {
			this.isSidebarToggle = !this.isSidebarToggle;
			emitter.emit('toggle-sidebar', this.isSidebarToggle);
		},
		
	}
});
