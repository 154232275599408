
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},

			link:'',
			controlBarEvent:'',
			screenType:''
		};
	},
	components:{
		audioVideoControlBar
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		propData: function (newVal, oldVal) {
			// [{id: '2', text: 'test2'}]
			console.log('Prop changed: ', newVal, ' | was: ', oldVal);
			this.$forceUpdate();
		}
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		backgroundImages(payload: any) {
			if (payload.imageUrl != '') {
				return {
					backgroundImage: `url("${payload.imageUrl}")`
				};
			} else {
				return {
					backgroundImage: `url("${process.env.VUE_APP_COMMON_IMAGE}")`
				};
			}
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	}
});
