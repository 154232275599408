
import useVuelidate from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { toast } from '../../main';
import spinner from '../../components/spinner.vue';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default defineComponent({
	components: {
		spinner
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		disableStatus:function():any{
			let disableStatusCondition:any=[];
			console.log('Dynamicity');
			console.log("this.modIndex",this.modIndex);
			console.log("this.dummy",this.dummy);
			let array:any=[]
			// this.dummy.forEach((item:any)=>{array.push('disable': )})
			if (this.currentScreen._id) {
				console.log('this.currentScreen', this.currentScreen);
				let rest = this.currentScreen;
				rest = {...this.currentScreen, moduleToggle: false}
				console.log('rest', rest);
				// console.log('this.modules[this.modIndex]', this.modules[this.modIndex]);
				// console.log("this.dummyObj",this.dummyObj)
				console.log("this.dummy[this.modIndex]",this.dummy[this.modIndex])
				console.log("_.isEqual(rest, this.modules[this.modIndex]",_.isEqual(rest, this.dummy[this.modIndex]));
				if (_.isEqual(rest, this.dummy[this.modIndex])) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			}
			this.setUpdateDisable(disableStatusCondition);
			// this.setUpdateDisable(this.modIndex,disableStatusCondition);
			console.log("disableStatusCondition",disableStatusCondition)
			return disableStatusCondition;

		}
	},
	data(): any {
		return {
			v$: useVuelidate(),
			spinnerLoading: false,
			teamsList: [],
			value: null,
			totalQuestions: 20,
			optionsarray: [],
			pageType: '',
			cousrseWithModuleId: '',
			coursemodule: true,
			createscreen: true,
			finalarray: [],
			moduleToggle: false,
			isloading: false,
			visibilityscreens: false,
			visibilityQuestionaire: false,
			dummyId: '000000000000000000',
			validationModuleName: false,
			allQuestions: [],
			activateButton: false,
			updatescreen: false,
			newScreen: false,
			showImageBlock:false,

			currentScreen:{},
			modIndex:'',
			dummy:[],
			updateDisable:[],

			courses: [
				{
					courseName: '',
					courseDescription: '',
					courseAuthor: '',
					courseImageUrl: '',
					courseDuration: '',
					courseCategory: '',
					courseType: '',
					courseImage: '',
					isActive: false,
					createdBy: '',
					createdAt: '',
					updatedBy: '',
					updatedAt: ''
				}
			],
			dummyData:[],
			// dummyModule:{},
			checkIds:[],
			imageUrl:'',
			moduleImageUrl:'',
			errorClass: false,
			modules: [],
			actionIcons: true,
			allScreensInactive:null,
			fileName:'',
			fileNameMod:[],
			fileStatus: [],
			fileStatusName:true,
		};
	},
	validations() {
		return {
			courses: [
				{
					courseName: { required },
					courseDescription: { required, maxLengthValue: maxLength(308), },
					courseType: { required }
					// courseImageUrl:{required}
				}
			],
			modules: {
				$each: helpers.forEach({
					moduleName: { required },
					moduleDescriptions: { required }
				})
			}
		};
	},

	methods: {
		// async changedInputControlValue(module: any) {
			// await this.getallScreens(module);		
			// if (module._id) {
			// 	module.updatescreen = true;
			// } else {
			// 	module.newScreen = true;
			// }

		// },
		setUpdateDisable(condition:any){
			console.log("disableStatus",this.disableStatus);
			this.updateDisable[this.modIndex] = condition;
			console.log("this.updateDisable",this.updateDisable)
		},
		updateIndex(index: any,e:any) {
			console.log('index', index);
			this.modIndex = index;
			// this.currentScreen = this.modules[this.modIndex];
			console.log("event",e);
			console.log("event",e.srcElement);
			console.log("event",e.srcElement.innerHTML);
			// if(e.srcElement.innerHTML == "Reset"){
			// 	this.resetData(this.modules[this.modIndex]);
			// }
			this.currentScreen = this.modules[this.modIndex];
			// this.setUpdateDisable();
		},
		resetData(singlemodule: any,index:any,e:any) {
			if(singlemodule._id){
				console.log("reset index",this.modIndex)
				this.modules[index] = {...JSON.parse(JSON.stringify(this.dummy[index])),moduleToggle:true};
			}
			else{
				(singlemodule.moduleName = ''), (singlemodule.moduleDescriptions = '');
				this.resetFileUploads(index);
			}
			console.log("eventReset",e)
		},
		allScreen(courses: any, singlemodule: any) {
			this.$router.push({
				name: 'updatescreen',
				params: { courseid: this.courses[0]._id, moduleid: singlemodule._id }
			});
		},
		checkFile(index:any) {
			let file = [];
			if(index !== undefined){
				console.log("inside mod");
				this.fileNameMod[index] = '';
				file = this.$refs[`element${index}`].files[0];
				file != undefined ? this.imgCheck(file,'module'):'';
			}
			else{
				console.log("inside Course");
				this.fileName = '';
				file = this.$refs.fileInput.files[0];
				file != undefined ? this.imgCheck(file,'course'):'';
				console.log("file",file);
			}	
		},
		imgCheck(file: any,status:any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Image should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads(this.modIndex)
				} 
				else {
					status == 'module' ? (this.fileNameMod[this.modIndex] = file.name,this.fileStatus[this.modIndex] = false) : (this.fileName = file.name,this.fileStatusName = false);
					
				}
			} else {
				toast.error('Supported only jpg, jpeg, png, svg', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads(this.modIndex)
			}
		},
		resetFileUploads(index:any){
			console.log("in reset ")
				if(this.$refs[`element${index}`].value){
					console.log("in reset")
					this.$refs[`element${index}`].value='';
					this.fileNameMod[index]='';
				}
		},

		async getallScreens(singlemodule: any) {
			this.visibilityscreens = !this.visibilityscreens;
			if (singlemodule.screens) {
				this.spinnerLoading = true;
				await this.$http
					.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/module/${singlemodule._id}/screens/getAll`)
					.then((res: any) => {
						this.spinnerLoading = false;
						singlemodule.screensArray = res.data;
						// this.finalarray = res.data;
						console.log('singlemodule.screensArraysinglemodule.screensArray',singlemodule.screensArray)
						let screensCheck = singlemodule.screensArray.every((obj:any)=>{
							return obj.data.isScreenActive == false;
						});
						if(screensCheck == true){
							this.allScreensInactive = true;
						}else{
							this.allScreensInactive = false;
						}
					})
					.catch((error: any) => {
						this.spinnerLoading = false;
						toast.error(error, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
			}
		},
		async getallImagesfromS3(){
			try {
					await this.$http
					.get(`${process.env.VUE_APP_FILE_API_URL}/bucket/files/getall`)
						 //.get(`http://localhost:4030/services/filemanagement/bucket/files/getall`)
						.then((response: any) => {
					if (response.status == 200) {
							for(var i=0;i<response.data.Contents.length;i++){
								let ids= response.data.Contents[i].Key.substring(0, response.data.Contents[i].Key.lastIndexOf('.'))
								this.checkIds.push(ids)
							}
						} 
					})
					} catch (err) {
					console.error(err);
					}

		},
		toggleModuleContainer(singlemodule:any,index:any) {
			// Your method logic here
			console.log('Method called');
			singlemodule.moduleToggle = !singlemodule.moduleToggle
			this.currentScreen = {...singlemodule};
			this.modIndex = index;
			// this.currentScreen = {}
		},
		async uploadImageData (data:any) {
						return new Promise(resolve => {
						const reader:any = new FileReader()
						reader.onload = function () {
						resolve(reader.result.replace('data:', '')
						.replace(/^.+,/, '')) 
						}
						reader.readAsDataURL(data)
						})
		},
		async sendDataImage(data:any){
				this.isloading=true
				console.log("ssslogged")
					// console.log("$refs.fileInput.files[0]",this.$refs.fileInput.files[0])
					const imageFile = await this.uploadImageData(data)
					let fileExtension=data.name.split('.').pop()
					let id=uuidv4();
					let findId =this.checkIds.filter((x:any) => x ===id )
					if(findId.length>0){
						id=uuidv4()
					}
					let payload={fileData:imageFile,name:`${id}.${fileExtension}`}
					

					try {
					await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
						// .post(`http://localhost:4030/services/filemanagement/bucket/${process.env.VUE_APP_TRAINING_API_PATH}/file/upload`, payload)
						.then((response: any) => {
					if (response.status == 200) {
							console.log(response.data)
							this.imageUrl=response.data
							this.isloading=false
							console.log("ssslogged",this.moduleImageUrl)
						} 
					else {
							console.log('response status', response.status);
						}
					})
					} catch (err) {
					console.error(err);
					}
			},

		async getallQuestionaireScreens(singlemodule: any) {
			this.visibilityQuestionaire = !this.visibilityQuestionaire;

			if (singlemodule.questionaire) {
				await this.$http
					.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${singlemodule._id}/questionairescreens/getAll`)
					.then((res: any) => {
						singlemodule.questionaireScreensArray = res.data;
					})
					.catch((error: any) => {
						toast.error(error, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
			}
		},
		async addScreen(smIndex: any) {
			this.$router.push({
				name: 'manage-screens',
				params: {
					courseId: this.$route.params.id,
					moduleId: this.modules[smIndex]._id
				}
			});
		},
		async addQuestionaire(smIndex: any) {
			this.$router.push({
				name: 'questionairescreen',
				params: {
					courseId: this.$route.params.id,
					moduleId: this.modules[smIndex]._id
				}
			});
		},
		async actionModule(smIndex: any, courses: any, singlemodule: any) {
			console.log("this.v$.modules",this.v$.modules);
			console.log("this.v$.modules[smIndex]",this.v$.modules[smIndex])
			this.v$.modules.$touch();
			// console.log("this.v$.modules[smIndex].$touch()",this.v$.modules[smIndex].$touch())
			this.modules[smIndex].validationModuleName = false;
			delete this.modules[smIndex].updatescreen;
			delete this.modules[smIndex].newScreen;
			const uniqueValues = new Set(this.modules.map((v: any) => v.moduleName));
			// if(this.$refs[`element${smIndex}`].files[0]!= undefined){
			// 	await this.sendDataImage(this.$refs[`element${smIndex}`].files[0])
			// }
			// else{
			// 		this.imageUrl=this.modules[smIndex].imageUrl
			// 		this.modules[smIndex].imageUrl=this.imageUrl
			// 	}
			if (uniqueValues.size < this.modules.length) {
				this.modules[smIndex].validationModuleName = true;
			}
		    console.log("this.v$",this.v$)
			let errorCondition = this.v$.modules.$each.$message[smIndex];
			if (errorCondition.length == 0 && !this.modules[smIndex].validationModuleName) {
				this.v$.$reset();
				let imageData=this.$refs[`element${smIndex}`].files[0]
				if(imageData !=undefined){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendDataImage(this.$refs[`element${smIndex}`].files[0])
						this.fileNameMod[smIndex] = '';
					}
				}
				if (singlemodule._id) {
					this.updateModule(courses, singlemodule);
				} else {
					this.saveModule(smIndex);
				}
				
			} else {
				singlemodule.errorClass = true;
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.fileNameMod[smIndex] = '';
			this.fileStatus[smIndex] = true;
		},

		async addCourseInformation(){
			let date = new Date();
				this.courses[0].createdBy = this.userInfo.sub;
				this.courses[0].createdAt = date;
				this.courses[0].updatedBy = this.userInfo.sub;
				this.courses[0].updatedAt = date;
				this.courses[0].courseImageUrl = this.imageUrl;
				this.courses[0].courseType = parseInt(this.courses[0].courseType);
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/courses/add`, this.courses)
					.then((response: any) => {
						if (response.status == 200) {
							this.$router.push({ name: 'courses' });
						} else {
							console.log('response status', response.status);
						}
					})
					.catch((error: any) => {
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
		},
		async addCourse() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let imageData=this.$refs.fileInput.files[0]
				if(this.$refs.fileInput.files[0] !=undefined){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendDataImage(this.$refs.fileInput.files[0])
						 this.addCourseInformation()
					}
					else {
					toast.error('Image Invalid Format', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				}
			}
				else{
					this.imageUrl=this.courses[0].courseImageUrl
					 this.addCourseInformation()
					}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}

			
		},
		async getSingleCourse() {
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/get`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.courses = res.data;
					this.dummyData = {...this.courses[0]} 
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
	  async	saveModule(smIndex: any) {
			this.modules[smIndex]['moduleToggle'] = true;
			delete this.modules[smIndex]['errorClass'];
			// delete this.modules[smIndex]['moduleToggle'];
			this.modules[smIndex]['isModuleActive'] = false;
			let date = new Date();
			this.modules[smIndex].createdBy = this.userInfo.sub;
			this.modules[smIndex].createdAt = date;
			// this.modules[smIndex].updatedBy = this.userInfo.sub;
			// this.modules[smIndex].updatedAt = date;
			this.modules[smIndex].imageUrl=this.imageUrl
			var updatedModule = [this.modules[smIndex]];
			this.spinnerLoading = true;
			this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/modules/add`, updatedModule)
				.then((response: any) => {
					toast.info('Module Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.modules[smIndex]['isModuleActive'] = false;
					this.getAllModules();
					this.spinnerLoading = false;
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Module not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},

	async getAllModules() {
			this.spinnerLoading = true;
		await	this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/modules/getAll`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.modules = [];
					this.modules = res.data;
					this.modules.forEach((module: any) => {
						module.moduleToggle = false;
					});
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.dummy = JSON.parse(JSON.stringify(this.modules));
				this.dummy.forEach((item:any)=>{this.updateDisable.push(false );this.fileNameMod.push('');this.fileStatus.push(true)});
		},
		async updateModule(courses: any, singlemodule: any) {
			await this.getallScreens(singlemodule);
			if(this.allScreensInactive == undefined || this.allScreensInactive == false){
				this.spinnerLoading = true;
			var moduleid = singlemodule._id;
			singlemodule.moduleToggle = true;
			delete singlemodule._id;
			// delete singlemodule.moduleToggle;
			delete singlemodule.errorClass;
			singlemodule.updatedBy = this.userInfo.sub;
			singlemodule.imageUrl=this.imageUrl
			singlemodule.updatedAt = new Date();
			delete singlemodule.screensArray;
			delete singlemodule.questionaire;
			delete singlemodule.screens;
			delete singlemodule.questionaireScreensArray;
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${courses[0]._id}/module/${moduleid}/update`, singlemodule)
				.then(async (response: any) => {
					this.spinnerLoading = false;
					toast.info('Module Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.updateDisable=[];
				 await  this.getAllModules();
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Module not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			}else{
				toast.error('No Screens are Active in this Module', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},

		addModule(): any {
			this.modules.push({
				moduleName: '',
				moduleDescriptions: '',
				createdBy: '',
				createdAt: '',
				updatedBy: '',
				updatedAt: '',
				imageUrl:'',
				moduleToggle: false,
				isModuleActive: false,
			});
		},
		async updateCourseInformation(){
			console.log("sss",this.imageUrl)
				delete this.courses[0]['_id'];
				delete this.courses[0]['modules'];
				this.courses[0].updatedBy = this.userInfo.sub;
				this.courses[0].updatedAt = new Date();
				this.courses[0].courseType = parseInt(this.courses[0].courseType);
				this.courses[0].courseImageUrl = this.imageUrl;
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/update`, this.courses[0])
					.then((response: any) => {
						if (response.status == 200) {
							this.$router.push({ name: 'courses' });
						} else {
							console.log('response status', response.status);
						}
					})
					.catch((error: any) => {
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
					});
					});
		},
		async updateSingleCourse() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let imageData=this.$refs.fileInput.files[0]
				if(this.$refs.fileInput.files[0] !=undefined){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendDataImage(this.$refs.fileInput.files[0])
						 this.updateCourseInformation()
					}
					else {
					toast.error('Image Invalid Format', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				}
			}
				else{
					this.imageUrl=this.courses[0].courseImageUrl
					 this.updateCourseInformation()
					}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.fileStatusName = true;
			
		},
		async deleteModule(courseid: any, singlemodule: any, smIndex: any) {
			this.spinnerLoading = true;
			await this.$http
				.delete(`${process.env.VUE_APP_TRAINING_API_URL}/course/${courseid[0]._id}/module/${singlemodule._id}/delete`)
				.then((response: any) => {
					this.spinnerLoading = false;
					toast.info('Module Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.getAllModules();
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Module Not Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		}
	},
	mounted() {
		this.getallImagesfromS3()
		this.pageType = this.$route.fullPath.includes('add') ? 'add' : 'edit';
		if (this.$route.params.id) {
			this.getSingleCourse();
			this.getAllModules();
		}
	}
});
