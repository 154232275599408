
import { emitter } from '@/main';
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
export default defineComponent({
	mixins: [globalApiMixin],
	props: ['propData'],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},
			labels: [],
			data: [],
			filteredCourses: [],
			
			link:'',
			controlBarEvent:'',
			screenType:'',
			openTab:0,
		};
	},
	components:{
		audioVideoControlBar
	},
	async mounted() {
		await this.fetchData();
		this.toggleTabs(0);
	},
	methods: {
		controlEvents(status:any,e:any){

			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else if(status == 'ended') {
					this.controlBarEvent = {'ended':'ended'};
			}else{
					this.controlBarEvent = {'noSrc':'e'};
			}
		},
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			emitter.emit('tabs', navIndex);
			this.filteredCourses = [];
			this.filteredCourses = this.data[navIndex];
			this.$refs.player.load();
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				// this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
				this.templatePayload.tabs.forEach((tab: any) => {
					this.labels.push(tab.label), this.data.push(tab);
				});
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					console.log
					// this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.templatePayload.tabs.forEach((tab: any) => {
						this.labels.push(tab.label), this.data.push(tab);
					});
				});
			}

		}
	}
});
