
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
	props: ['currentModule'],
	data(): any {
		return {
			moduleIndex: '',
			nextModules: '',
			showExam:false,
			courseModules:[],
			modulesData:[],
			examStatus:null,
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	methods:{
		modules(type: any) {
			if (type == 'nextmodule') {
				this.$emit('module', 'nextmodule');
			} else if (type == 'currentmodule') {
				this.$emit('module', 'currentmodule');
			} 
			else if (type == 'startExam') {
				this.$emit('module', 'startExam');
			}
			else {
				this.$emit('module', 'modules');
			}
		},
		async getModulesForCourse(){
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((res: any) => {
				this.modulesData = res.data[0].modules;
			})
		}
	},
	async mounted(){
		await this.getModulesForCourse();
		let examCondition = this.modulesData.every((obj:any)=>{
			return obj.completedScreens == obj.totalScreens
		});
		console.log('examexamCondition',examCondition)
		if(examCondition == true){
			this.examStatus = true;
		}else{
			this.examStatus = false;
		}
		this.moduleIndex=this.currentModule.moduleIndex+1;
		this.courseModules = this.currentModule.courses.modules.filter((module: any) =>{return module.screens && module.isModuleActive==true});
		let exam=this.courseModules.filter((module:any)=> Object.keys(module).includes('questionaire') )
		if(exam.length>0){
			this.showExam = true;
		}else{
			this.showExam = false;
		}
	}
	
});
