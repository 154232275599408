
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
// import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/outline';

export default defineComponent({
	mixins: [globalApiMixin],
	props: ['propData'],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},

			link:'',
			controlBarEvent:'',
			screenType:''
		};
	},
	components:{
		audioVideoControlBar
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		onopenAccordion(index: any) {
			for (var i = 0; i < this.templatePayload.accordions.length; i++) {
				if (i == index) {
					this.templatePayload.accordions[i].isActive = !this.templatePayload.accordions[i].isActive;
				} else {
					this.templatePayload.accordions[i].isActive = false;
				}
			}
		},

		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	}
});
