
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import axios from 'axios';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import { requiredIf } from '@vuelidate/validators';
import popUp from '@/components/popup.vue';
const numeric = helpers.regex(/^[0-9]*$/);
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			allCourses: [],
			isShow: false,
			teamsList: [],
			value: null,
			optionsarray: [],
			optionsUserArray: [],
			team: [],
			users: [],
			allUsers: [],
			singlecourse: [],
			dummyListForAllCourses: [],
			activateButton: false,
			updatescreen: false,
			statusCodeId: '',
			dummyStatusCodeId: '',
			dummyTeam: [],
			dummyUser: [],
			index: -1,
			newScreen: false,
			fileData: '',
			image: '',
			data: '',
			base64String: '',
			imageBase64Stringsep: '',
			dummySingleCourse:null,
		};
	},
	components: { Multiselect, popUp },
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	validations(index: any) {
		// console.log("index",index)
		return {
			allCourses: {
				$each: helpers.forEach({
					courseTargetTypeCodeId: { required },
					courseTypecodeId: { required },
					dueDays: { required, numeric },
					courseExpiry: { required, numeric },
					team: {
						required: requiredIf(() => {
							return this.statusCodeId == 10502;
						})
					},
					user: {
						required: requiredIf(() => {
							return this.statusCodeId == 10503;
						})
					}
				})
			}
		};
	},
	methods: {
		onLoad(singlecourse:any,scIndex:any){
			singlecourse.isShow=!singlecourse.isShow;
			this.index=scIndex;
			this.statusCodeId = singlecourse.courseTargetTypeCodeId;
			this.dummyTeam=[];
			this.dummyUser=[];
			this.dummyTeam=singlecourse.team.slice()
			this.dummyUser=singlecourse.user.slice()
			singlecourse.team=[];
			singlecourse.user=[];
			singlecourse.forceUpdate = false;
			this.cancel(singlecourse)
			this.dummySingleCourse = {...singlecourse}
		},
		changeTarget(course: any) {
			console.log('sss', course);
			this.statusCodeId = course.courseTargetTypeCodeId;
		},
		changedInputControlValue(course: any, event: any) {
			console.log("Inside Change")
			if (course.updatedAt == '' || !course.updatedAt) {
				course.newScreen = true;
			} else {
				course.updatescreen = true;
			}
			if(parseInt(course.courseTargetTypeCodeId)==10501){
				if(course.user.length!=0){ course.team=[];}
				if(course.team.length!=0){course.user=[];}	
			}
			else if(course.user.length!=0 && parseInt(course.courseTargetTypeCodeId)==10502){
				course.user=[];
			}
			else if(course.team.length!=0 && parseInt(course.courseTargetTypeCodeId)==10503){
				course.team=[];
			}
			this.checkingForSameData(course);
		},
		checkingForSameData(singleCourse:any){
			let course=singleCourse
			let num=Number(course.dueDays);
			let dueDays;
			if(num==0){dueDays=null}
			else{dueDays=num}
			let Scourse=[
				course.courseTypecodeId=parseInt(course.courseTypecodeId), dueDays, course.courseTargetTypeCodeId, course.courseExpiry, course.team.sort(), course.user.sort()
			]
			let dummy=this.dummyListForAllCourses[this.index]
			let Dcourse=[
				dummy.courseTypecodeId, dummy.dueDays, dummy.courseTargetTypeCodeId, dummy.courseExpiry, dummy.team=this.dummyTeam.sort(), dummy.user=this.dummyUser.sort()
			]
			if(JSON.stringify(Scourse)==JSON.stringify(Dcourse))
			{
				console.log("checking for changes...")
				singleCourse.updatescreen=false;
			}
		},
		reset(course:any){
			course.departments=[];
			course.courseTypecodeId='';
			course.dueDays='';
			course.courseExpiry=''
			course.courseTargetTypeCodeId=''
			this.v$.$reset();
		},
		async cancel(singleCourse:any){
			singleCourse.isShow =true
			console.log("In Cancel")
			let selectedCourse=this.dummyListForAllCourses.filter((course:any)=>course.masterCourseId==singleCourse.masterCourseId)
			singleCourse.courseTypecodeId=selectedCourse[0].courseTypecodeId
			singleCourse.dueDays=selectedCourse[0].dueDays
			singleCourse.courseTargetTypeCodeId=selectedCourse[0].courseTargetTypeCodeId
			singleCourse.courseExpiry=selectedCourse[0].courseExpiry
			singleCourse.users=selectedCourse[0].users
			singleCourse.departments=selectedCourse[0].departments
			singleCourse.user=[]
			singleCourse.team=[]
			if(parseInt(singleCourse.courseTargetTypeCodeId)==10503){
				console.log("target is user")
				for(let x=0;x<singleCourse.users.length;x++){
					if(singleCourse.user[x]!=singleCourse.users[x].userId){
						singleCourse.user.push(singleCourse.users[x].userId)
					console.log("user",singleCourse.users[x].userId)
					}
				}
			}
		    if(parseInt(singleCourse.courseTargetTypeCodeId)==10502){
				console.log("target is teams")
				for(let x=0;x<singleCourse.departments.length;x++){
					if(singleCourse.team[x]!=singleCourse.departments[x].departmentName){
						singleCourse.team.push(singleCourse.departments[x].departmentName)
					console.log("team",singleCourse.departments[x].departmentName)
					}
				}
			}
			this.checkingForSameData(singleCourse);
		},
		save(singlecourse: any, index: any) {
			console.log(this.v$.allCourses);
			this.v$.$touch();
			this.formTouched = !this.v$.allCourses.$anyDirty;
			this.errors = this.v$.allCourses.$anyError;

			let departments = [];
			let usersArray = [];
			if(parseInt(singlecourse.courseTargetTypeCodeId)==10501){
				departments=[],
				usersArray =[]
			}
			if (singlecourse.team.length!=0  && parseInt(singlecourse.courseTargetTypeCodeId)==10502) {

					for (let department of singlecourse.team) {
						var temp = this.teamsList.find((team: any) => {
						return team.teamName == department;
						});
						departments.push({
						departmentName: temp.teamName,
						departmentId: temp._id
						});
					}
			}
			if (singlecourse.user.length!=0 && parseInt(singlecourse.courseTargetTypeCodeId)==10503) {
					console.log("Inside users")
					for (let userId of singlecourse.user) {
						var singleUser = this.users.find((users: any) => {
							return users._id == userId;
					});
					usersArray.push({
						userName: singleUser.name,
						userId: singleUser._id,
						userEmail: singleUser.email
					});
				}
			}
			const toast = useToast();
			var updateDetails = {};
			let team = this.teamsList.find((team: any) => {
				return team.teamName == singlecourse.team;
			});
			let errorCondition = this.v$.allCourses.$each.$message[index];
			// stop here if form is invalid
			// if (this.$v.$invalid) return;
			if (errorCondition.length == 0) {
				this.v$.$reset();
				singlecourse.isShow = false;
				singlecourse.updatescreen=false;
				updateDetails = {
				masterCourseId: singlecourse.masterCourseId,
				courseName: singlecourse.courseName,
				courseTargetTypeCodeId: parseInt(singlecourse.courseTargetTypeCodeId),
				dueDays: parseInt(singlecourse.dueDays),
				departments: departments,
				courseExpiry: singlecourse.courseExpiry,
				users: usersArray,
				updatedAt:moment().format('DD-MM-YYYY'),
				forceUpdate:singlecourse.forceUpdate,
				// departmentId: team._id,
				// departmentName:team.teamName,
				courseTypecodeId: parseInt(singlecourse.courseTypecodeId)
			};
			this.dummyListForAllCourses[index]={...this.dummyListForAllCourses[index], ...singlecourse, ...updateDetails}

			this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/course/${singlecourse._id}`, updateDetails)
				.then((response: any) => {
					if (response.status == 200) {
						toast.info('Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						singlecourse.isShow =false
						this.createscreen = true;
					}
				})
				.catch((error: any) => {
					toast.error('Not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					singlecourse.isShow =true
				});
			}
			else{
				singlecourse.isShow = true;	
				singlecourse.updatescreen=true;
				console.log("Inside errorCondition.length!=0")
				if(singlecourse.courseTargetTypeCodeId!=this.dummyStatusCodeId)
				{
					this.dummyStatusCodeId=singlecourse.courseTargetTypeCodeId;	
				}
			}
		},

		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.users = res.data;
			});
			let activeUsers = this.users.filter((user: any) => {
				return user.isActive == true;
			});
			for (var i = 0; i < activeUsers.length; i++) {
				this.optionsUserArray.push({ label: activeUsers[i].name, value: activeUsers[i]._id });
			}
		},
		async getAllRootCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/courses/getAll`).then((res: any) => {
				this.allRootCourses = res.data; 
			});
		},
		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				this.allCourses = res.data;
				for(var i=0;i<this.allCourses.length;i++){
					for(var j=0;j<this.allRootCourses.length;j++){
						if(this.allCourses[i].masterCourseId==this.allRootCourses[j]._id){
							this.allCourses[i].courseDescription=this.allRootCourses[j].courseDescription
							this.allCourses[i].courseImage=this.allRootCourses[j].courseImageUrl
						}

					}
				}
				this.dummyListForAllCourses = JSON.parse(JSON.stringify(res.data));
				// this.allCourses[0].masterCourseId = '622f2e4a64be003d6cc631fe';
				this.allCourses.map((course: any) => {
					if (!course.courseTargetTypeCodeId && !course.courseTypecodeId && !course.dueDays && !course.courseExpiry) {
						course.courseTargetTypeCodeId = '';
						course.courseTypecodeId = '';
						course.dueDays = '';
						course.courseExpiry = '';
						course.user = [];
						course.team=[];
					}
					// course.user = [];
					if (course.departments) course.team = course.departments.map((a: any) => a.departmentName);
					if (course.users) {
						course.user = course.users.map((a: any) => a.userId);
					}
				});
			});
		},
		async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teamsList = res.data;
			});
			for (var i = 0; i < this.teamsList.length; i++) {
				this.optionsarray.push(this.teamsList[i].teamName);
			}
		}
	},
	async mounted() {
		await this.getAllRootCourses()
		this.getAllUsers();
		await this.getAllCourses();
		this.getAllTeams();
		
	}
});
