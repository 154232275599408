
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
export default defineComponent({
	mixins: [globalApiMixin],
	props: ['propData'],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},
			checkedoptions: [],
			showResetbutton: true,

			link:'',
			controlBarEvent:'',
			screenType:''
		};
	},
	components:{
		audioVideoControlBar
	},
	async mounted() {
		await this.fetchData();
		this.onReset();
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		onSave(options: any) {
		if(this.checkedoptions.length>0){
				for (var i = 0; i < options.length; i++) {
				// if (options[i].label == this.checkedoptions) {
					if (options[i].isCorrect == true) {
						options[i].isRight = true;
					}else {
						options[i].isWrong = true;
					}
				// }
			}
		}
		},
			backgroundImages(payload: any) {
			if (payload.imageUrl != '') {
				return {
					backgroundImage: `url("${payload.imageUrl}")`
				};
			} else {
				return {
					backgroundImage: `url("${process.env.VUE_APP_COMMON_IMAGE}")`
				};
			}
		},
		onReset() {
			this.checkedoptions = [];
			for (var i = 0; i < this.templatePayload.options.length; i++) {
				this.templatePayload.options[i].isRight = false;
				this.templatePayload.options[i].isWrong = false;
			}
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					var listOfOptions = response.data[0].data;
					for (var i = 0; i < listOfOptions.options.length; i++) {
						listOfOptions.options[i].isRight = false;
						listOfOptions.options[i].isWrong = false;
					}
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	}
});
