
import { defineComponent } from 'vue';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
     props: ['popUp'],
    data(): any {
        return {
            v$: useVuelidate(),
            pop: false
        };
    },
    created() {
        this.pop = this.popUp;
    },
    beforeUnmount(){
         this.pop = !this.popUp;
           if (this.pop == false) {
                console.log("false popUp")
                document.body.style.overflow = '';
           }
    },
    watch: {
        popUp(newVal,oldVal){
            this.pop = newVal;
            console.log("this.pop",this.pop)
        },
        pop(newVal, oldVal) {
             if (newVal == true) {
                console.log("true popUp")
                document.body.style.overflow = 'hidden';
            }     
        }
    }
});
