import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import defaultLayout from "@/layouts/defaultLayout.vue";
import emptyLayout from "@/layouts/emptyLayout.vue";
import adminlayout from "@/layouts/adminLayout.vue";
import coursesHome from "@/features/root-admin/coursesHome.vue";
import addCourse from "@/features/root-admin/addCourse.vue";
import manageScreen from "@/features/root-admin/manageScreens.vue";
import adminDashboard from "@/features/client-admin/adminDashboard.vue";
import dashboard from "@/features/client-admin/dashboard.vue";
import questionaireScreen from "@/features/root-admin/questionaireScreen.vue"
import masterDashboard from "@/features/root-admin/masterDashboard.vue";
import userDashboard from "@/features/end-user/userDashboard.vue";
import courseDetails from "@/features/end-user/courseDetails.vue";
import courseModules from "@/features/end-user/courseModules.vue";
import courseScreens from "@/features/end-user/courseScreens.vue";
import testScreen from "@/features/end-user/testScreen.vue";
import radioTemplate from "@/templates/radioTemplate.vue";
import checkboxTemplate from "@/templates/checkboxTemplate.vue";
import textTemplate from "@/templates/textTemplate.vue";
import hAccordion from "@/templates/hAccordion.vue";
import vAccordion from "@/templates/vAccordion.vue";
import tabTemplate from "@/templates/tabTemplate.vue";
import TextWithBulletsTemplate from '@/templates/textwithbulletsTemplate.vue'
import commonTemplate from "@/templates/commonTemplate.vue"
import { adminNavigationGuard, organisationManagementNavigationGuard } from "./navGuard";
import videoTemplate from "@/templates/videoTemplate.vue";

const templateRoutes: Array<RouteRecordRaw> = [
    {
        path: "/admin/template/tab/:screenId",
        name: "tab-template",
        meta: {
            title: "Tab Template",
            layout: emptyLayout,
        },
        component: tabTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/video/:screenId",
        name: "video-template",
        meta: {
            title: "Video Template",
            layout: emptyLayout,
        },
        component: videoTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/textbullet/:screenId",
        name: "textbullet-template",
        meta: {
            title: "TextWithBulletsTemplate",
            layout: emptyLayout,
        },
        component: TextWithBulletsTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/commonTemplate",
        name: "common-template",
        meta: {
            title: "common Template",
            layout: emptyLayout,
        },
        component: commonTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/radio/:screenId",
        name: "radio-template",
        meta: {
            title: "Radio Template",
            layout: emptyLayout,
        },
        component: radioTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/checkbox/:screenId",
        name: "checkbox-template",
        meta: {
            title: "Checkbox Template",
            layout: emptyLayout,
        },
        component: checkboxTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/text/:screenId",
        name: "radio-text",
        meta: {
            title: "Text Template",
            layout: textTemplate,
        },
        component: radioTemplate,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/vaccordion/:screenId",
        name: "vaccordion-template",
        meta: {
            title: "V Accordion Template",
            layout: emptyLayout,
        },
        component: vAccordion,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/template/haccordion/:screenId",
        name: "haccordion-template",
        meta: {
            title: "H Accordion Template",
            layout: emptyLayout,
        },
        component: hAccordion,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
]
const orgRoutes: Array<RouteRecordRaw> = [
    
    {
        path: "/org/course/:courseId?",
        name: "course-details",
        meta: {
            title: "Course Details",
            layout: defaultLayout,
        },
        component: courseDetails,
    },
    {
        path: "/org/course/:courseId?/modules",
        name: "course-modules",
        meta: {
            title: "Course Modules",
            layout: emptyLayout,
        },
        component: courseModules,
    },
    {
        path: "/org/course/:courseId?/modules/:moduleId?/screens/:activeScreen",
        name: "screens",
        meta: {
            title: "Screens",
            layout: emptyLayout,
        },
        component: courseScreens,
    },
    {
        path: "/org/courses",
        name: "user-dashboard",
        meta: {
            title: "My Courses",
            layout: defaultLayout,
        },
        component: userDashboard,
    },
    {
        path: "/org/manage/courses",
        name: "course-management",
        meta: {
            title: "Manage Courses",
            layout: defaultLayout,
        },
        component: adminDashboard,
        beforeEnter: (to, from, next) => {
          organisationManagementNavigationGuard(to, from, next)
        }
    },
    {
        path: "/org/dashboard",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        component: dashboard,
        beforeEnter: (to, from, next) => {
          organisationManagementNavigationGuard(to, from, next)
        }
    },

    {
        path: "/org/course/:courseId?/test",
        name: "test-screen",
        meta: {
            title: "test-screen",
            layout: emptyLayout,
        },
        component: testScreen,
    },

]
const adminRoutes: Array<RouteRecordRaw> = [

    {
        path: "/admin/courses/getall",
        name: "courses",
        meta: {
            title: "Courses",
            layout: adminlayout,
        },
        component: coursesHome,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/courses/add",
        name: "add-course",
        meta: {
            title: "Add Course",
            layout: adminlayout,
        },
        component: addCourse,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/course/:id?/edit",
        name: "edit-course",
        meta: {
            title: "Edit Course",
            layout: adminlayout,
        },
        component: addCourse,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },

    {
        path: "/admin/courses/:courseId?/modules/:moduleId?/screens/manage",
        name: "manage-screens",
        meta: {
            title: "Manage Screens",
            layout: adminlayout,
        },
        component: manageScreen,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/courses/:courseId?/modules/:moduleId?/screens/questionaire",
        name: "questionairescreen",
        meta: {
            title: "questionairescreen",
            layout: adminlayout,
        },
        component: questionaireScreen,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        }
    },
    {
        path: "/admin/master",
        name: "master",
        meta: {
            title: "Clients",
            layout: adminlayout,
        },
        component: masterDashboard,
        beforeEnter: (to, from, next) => {
          adminNavigationGuard(to, from, next)
        },
    },



]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'default',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        redirect: { name: 'dashboard' }
    },
    {
        path: '/admin',
        name: 'default-admin',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        redirect: { name: 'courses' }
    },
    {
        path: '/error',
        name: 'error',
        meta: {
            title: 'error',
            layout: 'empty-layout',
        },
        props:true,
        component: () => import('@/components/error.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/error',
    },
    ...templateRoutes,
    ...orgRoutes,
    ...adminRoutes

]
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.state.isLoading = true;
    if(to.meta.title){
        document.title=`Training - ${to.meta.title}`
    }
    else{
        document.title=`Training`
    }
    console.log('Router check triggered', to)
    if (to.path == '/org/dashboard' || to.path == '/admin' || to.path == '/admin/courses/getall' || to.path == '/') {
        console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    // next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if(!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if(!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else{
            next();
        }
    }
}



export default router;