
import navbar from '@/components/navbar.vue';
import { defineComponent } from 'vue';
import Footer from '@/components/footer.vue'
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		navbar,
		Footer
	},
	data() {
		return {
			isToggling: true,
			isSidebarToggle:true,
			appsVisible:false,
			organisation:false,
			courses:false,
			passingRouteName:'',
			selectedOptionName:'',
			menus: [
				{
					optionName: 'master',
					optionRoute: 'master',
					defaultoptionImage: require('@/assets/organisationDefault.svg'),
					colouredoptionImage: require('@/assets/organisationColoured.svg'),
					displayOptionName: 'Organization',
				},
				{
					optionName: 'courses',
					defaultoptionImage: require('@/assets/coursesDefault.svg'),
					colouredoptionImage: require('@/assets/coursesColoured.svg'),
					optionRoute: 'courses',
					displayOptionName: 'Courses',
				}
			]
		};
	},
	computed:{
		...mapGetters({ toggleState:'toggle' })
	},
	mounted() {
		this.isToggling = this.toggleState;
		this.isSidebarToggle=this.toggleState;
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isToggling = isToggle;
		});
		emitter.on('apps-sidemenubar', (isToggle: any) => {
			this.appsVisible = isToggle;
		});
	},
	async created(){
		if(this.$route.path.includes('master')){
            this.passingRouteName = 'master'
        }else if(this.$route.path.includes('courses')||this.$route.path.includes('course')){
            this.passingRouteName = 'courses'
        }
		this.selectedOptionName = this.passingRouteName;
	},
	methods: {
		closeApps(){
			this.appsVisible=false;
		emitter.emit('apps', this.appsVisible);
		},
		triggerNavToAdminSettings() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
		},
		triggerNav() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_MONITOR_WEBAPP_URL}`;
		},
		toggleSideMenuBar() {
			this.isToggling = !this.isToggling;
			this.isSidebarToggle=!this.isSidebarToggle
			emitter.emit('toggle-sidebar', this.isSidebarToggle);
		},
		navigate(name:any, routerOption:any) {
			this.$store.dispatch('selectedRoute', name);
			this.$store.dispatch('selectedToggle',this.isToggling);
			this.$router.push({ name: routerOption });
		},
	}
});
