
import { defineComponent } from 'vue';
import moment from 'moment';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
// import audioVideoControlBar from '@/components/audioVideoControlBar.vue';
export default defineComponent({
	// props: ['src', 'poster'],

	props: ['propData'],
    mixins: [globalApiMixin],
	data(): any {
		return {
			playerControls: '',
			videoCurrentposition: '',
			videoTotalDuration: '',
			duration: '',
			progress: '',
			playerButton: false,
			toggleMute: false,
			togglePlay: false,
			link: '',
			poster: '',

			dataPopulated: false,
			templatePayload: {}
		};
	},
	async mounted() {
		await this.fetchData();
		this.$refs.player.muted = false;
		document.addEventListener('keydown', (event) => {
			if (event.key == 'MediaPlayPause') {
				this.playPause();
			}
		});
	},
	methods: {
		mute() {
			this.toggleMute == false ? ((this.toggleMute = true), (this.$refs.player.muted = true)) : ((this.toggleMute = false), (this.$refs.player.muted = false));
		},
		playPause(status: any) {
			if (status == 'ended') {
				this.$refs.player.pause(), (this.togglePlay = false);
				this.$emit('video',true);
			} else {
				this.$refs.player.paused == false ? (this.$refs.player.pause(), (this.togglePlay = false)) : (this.$refs.player.play(), (this.togglePlay = true));
			}
		},
		start(e: any) {
			this.videoCurrentposition = this.formatTime(e.srcElement.currentTime);
			this.videoTotalDuration = this.formatTime(e.srcElement.duration);
			this.duration = e.srcElement.duration;
			this.progressPercentage(e.srcElement.currentTime, this.duration);
			this.$emit('video',false);
		},
		fullScreenToggle() {
			if (document.fullscreenElement) {
				document.exitFullscreen();
			} else {
				this.$refs.player.requestFullscreen();
			}
		},
		formatTime(sec: any) {
			var hours: any = Math.floor(sec / 3600);
			hours >= 1 ? (sec = sec - hours * 3600) : (hours = '00');
			var min: any = Math.floor(sec / 60);
			min >= 1 ? (sec = sec - min * 60) : (min = '00');
			sec < 1 ? (sec = '00') : void 0;
			sec = parseInt(sec);
			min.toString().length == 1 ? (min = '0' + min) : void 0;
			sec.toString().length == 1 ? (sec = '0' + sec) : void 0;
			if (hours == '00') return min + ':' + sec;
			else return hours + ':' + min + ':' + sec;
		},
		update(e: any) {
			this.videoCurrentposition = this.formatTime(e.srcElement.currentTime);
			this.progressPercentage(e.srcElement.currentTime, this.duration);
		},
		progressPercentage(currentTime: any, duration: any) {
			var widthModified: any = ((currentTime / duration) * 100).toFixed(2);
			this.progress = { width: widthModified + '%' };
			((widthModified/100)*940 > 11) ? (this.playerButton = true) : '';
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
                this.link = this.templatePayload.videoUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.videoUrl;
					this.$refs.player.load();
				});
			}
		}
	}
});
