
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { toast, emitter } from '../../main';
import VueApexCharts from 'vue3-apexcharts';
export default defineComponent({
	components: { apexchart: VueApexCharts },
	data(): any {
		return {
			courseDetails: {},
			modules: [],
			userActivity: {},
			completedBy: '',
			closedroute: {},
			new_window: {},
			startedAt: '',
			seriesProgressData: [],
			chartOptions: {
				type: 'radialBar',
				colors: ['#152a43'],
				labels: ['Course'],

				plotOptions: {
					radialBar: {
						// dataLabels: {
						// 	show: false
						// },

						dataLabels: {
							name: {
								show: false

								// fontSize: '17px'
							},
							value: {
								fontSize: '35px',
								show: true
							}
						},

						hollow: {
							margin: 15,
							size: '64%'
							// background: '#152a43'
						}
						// track:{
						// 	background: '#152a43'
						// }
					}
				}
			},

			series: []
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		Images(payload: any) {
			console.log(payload.courseImageUrl);
			if (payload.courseImageUrl != '' && payload.courseImageUrl != undefined) {
				return payload.courseImageUrl;
			} else {
				return process.env.VUE_APP_COMMON_IMAGE;
			}
		},

		async getAllModules() {
			console.log(this.$route.params.id);
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/modules/getAll`)
				.then(async (res: any) => {
					// this.spinnerLoading = false;
					this.modules = res.data;
					this.modules = this.modules.filter((module: any) =>{ return module.screens && module.isModuleActive == true});
					await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((a: any) => {
						this.userActivity = a.data[0];

						// if(this.userActivity.examStatus){
						// 	let date = new Date(this.userActivity.examStatus.startedAt);
						// 	this.completedBy = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						// }
						// else if(this.userActivity.modules) {
						// 		let module = this.userActivity.modules.length - 1;
						// 	let date = new Date(this.userActivity.modules[module].updatedAt);
						// 	this.completedBy = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						// }
						 if (this.userActivity.updatedAt) {
						// if (this.userActivity.totalModules == this.userActivity.completedModules) {
							let date = new Date(this.userActivity.updatedAt);
							this.completedBy = (`0`+(date.getDate() )).slice(-2) + '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
					
						}
						else{
							this.completedBy =''
						}

						if(this.userActivity.examStatus){
							let date = new Date(this.userActivity.examStatus.startedAt);
							this.startedAt = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						}
						else if (this.userActivity.modules) {
							let date = new Date(this.userActivity.modules[0].updatedAt);
							this.startedAt = (`0`+(date.getDate() )).slice(-2)+ '-' + (`0`+(date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
						} 
						
						
							else {
							this.startedAt = '';
						}
						let exam=this.modules.filter((module:any)=> module.questionaire )
						if(exam.length>0 ){	
							if(this.userActivity.examStatus){
									this.userActivity.totalModules=this.userActivity.examStatus ? this.userActivity.totalModules+1:this.userActivity.totalModules+0
									var resultPercentage = Math.round((this.userActivity.examStatus.pass / this.userActivity.examStatus.total) * 100);
							if (resultPercentage < 80) {
									this.userActivity.completedModules=this.userActivity.completedModules+0
							} else {
									this.userActivity.completedModules=this.userActivity.completedModules+1
							}
							}else{
								this.userActivity.totalModules=this.userActivity.totalModules+1
							}
					
						}
						else{
							this.userActivity.totalModules=this.userActivity.totalModules+0
						}
						let moduleCompletion = Math.round((this.userActivity.completedModules / this.userActivity.totalModules) * 100) || 0;
							if(moduleCompletion>100){
								moduleCompletion =100
							}
						this.series.push(moduleCompletion);
					});
				})
				.catch((error: any) => {
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onExit(){
			window.close()
		},
		startCourse(module: any): any {
			var left = (screen.width - 900) / 2;
			var top = (screen.height - 600) / 4;
			let payload={
				totalModules:this.modules.length
			}
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/updateModuleInUserActivity`, payload)
					.then((response: any) => {
					console.log(response)
					});
			
			// this.$router.push({
			// 	name: 'course-modules', params: { courseId: this.$route.params.courseId }
			// });
			let routeData = this.$router.resolve({ name: 'course-modules', params: { courseId: this.$route.params.courseId } });
			// window.open(routeData.href,"_blank", "width="+screen.availWidth+",height="+screen.availHeight)
			
     		 let pageWindow = window.open(routeData.href, '_blank', 'width=' + screen.availWidth + ',height=' + screen.availHeight);
      			if (pageWindow) {
        			// Listen for messages from Page B
        			window.addEventListener('message', this.handlePage);
      				}
 
			// window.open(routeData.href,'_blank','toolbar=yes,scrollbars=yes')
			// this.closedroute = window.open(routeData.href,'_blank,toolbar=yes,scrollbars=yes,resizable=yes');
			// this.new_window = window.open(routeData.href, '_blank', 'width=900, height=580, top=' + top + ',left=' + left + ',toolbar=yes,scrollbars=yes,resizable=yes fullscreen');
		},
		  			handlePage(event:any) {
     			 if (event.data === 'pageClosed') {
        				// Page B is closed, refresh Page A
       				 location.reload();
      				}
			},
		async downloadCerificate(userInfo:any){
					this.$http({
                    url: `${process.env.VUE_APP_TRAINING_API_URL}/user/${userInfo._id}/course/${this.userActivity.masterCourseId}/certificate`, // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res:any) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', `${this.courseDetails.courseName}.pdf`);
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
			
		}
		// downloadCerificate:function(userInfo:any):any{
		// 	return `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/user/${userInfo._id}/course/${this.userActivity.masterCourseId}/certificate`;
		// }
		
	},

	async created() {
		await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`).then((res: any) => {
			this.courseDetails = res.data[0];
		});
	},
	async mounted() {
		await this.getAllModules();
	}
});
