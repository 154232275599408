
import { defineComponent } from 'vue';
import displaycard from '@/features/root-admin/display-card.vue';
import { mapGetters } from 'vuex';
import { globalApiMixin } from '../../GlobalMixins/apiGlobalMixins';
import { emitter } from '../../main';
export default defineComponent({
	mixins: [globalApiMixin],
	data(): any {
		return {
			x: '',
			isvertically: true,
			openTab: '',
			allClientCourses:[],
			filteredCourses: [],
			userAssignedCourses:[],
			navBarheading: ['All', 'In Progress', 'Overdue', 'Not Started', 'Completed'],
			finalCourses: []
		};
	},
	components: {
		displaycard,
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		classObject: function (): any {
			return {
				// grid: this.isvertically,
				// relative: this.isvertically,
				// 'grid-cols-1': this.isvertically,
				// 'md:grid-cols-2': this.isvertically,
				// 'lg:grid-cols-3': this.isvertically,
				// 'gap-6': this.isvertically

				flex:this.isvertically,
				relative: this.isvertically,
				'gap-4': this.isvertically,
				
				//  'justify-between':this.isvertically,
				 'flex-wrap':this.isvertically,
				 'customVertically':this.isvertically,
			};
		}
	},
	methods: {
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			this.filteredCourses = [];
			switch (navIndex) {
				case 0:
					this.filteredCourses = this.finalCourses;
					break;
				case 1:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10402;
					});
					break;
				case 2:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10404;
					});
					break;
				case 3:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10401;
					});
					break;
				case 4:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10403;
					});
					break;
			}
		},
		openCourseDetail(courseId: any) {
			this.$router.push({
				name: 'course-details',
				params: { courseId: courseId }
			});
		},
		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/user/${this.userInfo._id}/courses`).then((res: any) => {
				this.userAssignedCourses=res.data;
				/* If course is activated to organisation and course completed and again course is unassigned for organisation the course should hide */
				for(var i=0;i<this.allClientCourses.length;i++){
					for(var j=0;j<this.userAssignedCourses.length;j++){
						if(this.userAssignedCourses[j]._id==this.allClientCourses[i].masterCourseId){
							this.finalCourses.push(this.userAssignedCourses[j])	
						}
					}
				}
				return this.finalCourses 
			});
		},
		async getAllClientCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				this.allClientCourses = res.data;
			})
			},

		ontoggle(toggledstate: any) {
			if (toggledstate == 'vertical') {
				this.isvertically = true;
			} else {
				this.isvertically = false;
			}
		}
	},
	async mounted() {
		await this.getAllClientCourses()
		await this.getAllCourses();
		this.toggleTabs(0);
	}
});
