
import { ChevronLeftIcon, ChevronRightIcon, VolumeUpIcon, VolumeOffIcon, MenuIcon } from '@heroicons/vue/solid';
import VerticallyAccordionScreen from '@/templates/vAccordion.vue';
import HorizantallyAccordionScreen from '@/templates/hAccordion.vue';
import CheckBoxScreen from '@/templates/checkboxTemplate.vue';
import RadioScreen from '@/templates/radioTemplate.vue';
import TextBoxScreen from '@/templates/textTemplate.vue';
import commonTemplate from '@/templates/commonTemplate.vue';
import TabScreen from '@/templates/tabTemplate.vue';
import TextWithBulletPointsScreen from '@/templates/textwithbulletsTemplate.vue';
import VideoScreen from "@/templates/videoTemplate.vue";
import { emitter, toast } from '../../main';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default defineComponent({
	components: {
		Carousel,
		Pagination,
		Navigation,
		Slide,
		ChevronLeftIcon,
		ChevronRightIcon,
		commonTemplate,
		HorizantallyAccordionScreen,
		VerticallyAccordionScreen,
		CheckBoxScreen,
		TabScreen,
		TextWithBulletPointsScreen,
		RadioScreen,
		TextBoxScreen,
		VideoScreen,
		VolumeUpIcon,
		VolumeOffIcon,
		MenuIcon
	},
	data(): any {
		return {
			slideIndex: 0,
			slides: [],
			screenNames: [],
			courses: [],
			modules: [],
			modulesIndex: 0,
			tabIndex:1,
			leftArrow:false,	
			nextButton:true,
			activeModules:[],		
		};
	},

	computed: {
		currentTabComponent: function (): any {
			console.log('cureent', this.slides[this.slideIndex].screenType);
			return this.slides[this.slideIndex].screenType;
		},
		currentProperties: function (): any {
			console.log("propData: this.slides[this.slideIndex].data ", this.slides[this.slideIndex].data )
			return {
				propData: this.slides[this.slideIndex].data };
		},
		currentKey:function():any{
			return this.slides[this.slideIndex]._id
		},
		currentModuleProperties: function (): any {
			return { moduleIndex: this.modulesIndex, courses: this.courses[0] };
		},

		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		toggleNextButton(status:any){
		   this.nextButton = status;
		},
		moveSlide(direction: string) {
			this.nextButton = true;
			if (direction == 'forward') {
				if (this.slideIndex == this.slides.length - 1) {
					this.endOfModule();
				} else {
					this.updateStatus(false);
				}
				this.screenNames[this.slideIndex].isActive = false;
				this.slideIndex += 1;
				this.screenNames[this.slideIndex].isActive = true;
			} else {
				this.screenNames[this.slideIndex].isActive = false;
				this.slideIndex -= 1;
				this.screenNames[this.slideIndex].isActive = true;
			}
		},
		goToTest() {
			this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
			// var closedroute: any = window.open(routeData.href, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
		},
		updateStatus(isEndOfModule: boolean) {
			let updatePayload;
			if (!isEndOfModule) {
				updatePayload = [
					{
						endOfModule: isEndOfModule,
						moduleId: this.$route.params.moduleId,
						statusCodeId: 10402,
						updatedAt: new Date(),
						screen: {
							screenId: this.slides[this.slideIndex]._id,
							statusCodeId: 10403
						},
						totalScreens: this.slides.length,
						completedScreens: this.slideIndex
					}
				];
			} else {
				updatePayload = [
					{
						endOfModule: isEndOfModule,
						moduleId: this.$route.params.moduleId,
						statusCodeId: 10403,
						updatedAt: new Date(),
						screen: {
							screenId: this.slides[this.slideIndex]._id,
							statusCodeId: 10403
						},
						totalScreens: this.slides.length,
						completedScreens: this.slideIndex
					}
				];
			}
			this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/user/${this.userInfo._id}/course/${this.$route.params.courseId}`, updatePayload);
		},
		endOfModule() {
			this.updateStatus(true);
			this.$router.push({
				name: 'course-modules',
				params: { courseId: this.$route.params.courseId }
			});
		},
		 handleBeforeUnload() {
      // Notify the parent window
      window.opener.postMessage('pageClosed', '*');
   		 },
		onExit() {
			window.close();
		},
		async getSingleCourse() {
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.courses = res.data;
					this.modules = this.courses[0].modules.filter((module: any) => {return module.isModuleActive == true});
					this.modules.push({
						moduleName: 'Start Exam',
						moduleId:'11111',
						createdBy: '',
						createdAt: '',
						updatedBy: '',
						updatedAt: '',
						screens:['1334556577']
					})
					console.log("ssss",this.modules)
					this.activemodules = this.courses[0].modules.filter((module:any)=>{return module.isModuleActive == true && Object.keys(module).includes('screens')})
					this.modulesIndex = this.activemodules.findIndex((object: any) => {
						return object._id === this.$route.params.moduleId;
					});
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
				});
		},

		async getModuleAction(value: any) {
			if (value == 'nextmodule') {
				this.updateStatus(true);
				this.modulesIndex = this.modulesIndex + 1;
				let updatedModule = this.activemodules[this.modulesIndex];
				
				(this.slides = []), 
					(this.screenNames = []),
					await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${updatedModule._id}/screens/getAll`).then((res: any) => {
						res.data.forEach((item:any)=>{if(!Object.keys(item.data).includes("isScreenActive")){item.data.isScreenActive = true}
						})
						var filteredScreens = res.data.filter((item:any)=>{{return item.data.isScreenActive == true}});
						this.slides = filteredScreens;
						this.slides.push({
							_id: '626aa23b0620124dec6c548s6',
							createdBy: '118101476679108383767',
							updatedBy: '118101476679108383767',
							createdAt: '2022-04-28T14:18:35.639Z',
							updatedAt: '2022-04-28T14:18:35.639Z',
							screenName: 'Screen',
							screenType: 'commonTemplate',
							order: '',
							screenStyles: {
								backgroundimage: '',
								color: 'red'
							},
							data: {},
						});
						this.slides.forEach((singleScreen: any) => {
							this.screenNames.push({ screenName: singleScreen.screenName, isActive: false });
						});
					});
				this.slideIndex = 0;
				this.$router.push({ name: 'screens', params: { courseId: this.courses[0]._id, moduleId: this.activemodules[this.modulesIndex]._id, activeScreen: 0 } });
				this.screenNames[this.slideIndex].isActive = true;
			} else if (value == 'currentmodule') {
				this.updateStatus(true);
				this.slideIndex = 0;
				this.$router.push({ name: 'screens', params: { courseId: this.$route.params.courseId, moduleId: this.$route.params.moduleId, activeScreen: 0 } });
			} else if (value == 'startExam') {
				this.updateStatus(true);
				this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
				// var closedroute: any = window.open(routeData.href, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
			} else if (value == 'modules') {
				console.log('logged');
				this.endOfModule();
			}
		}
	},
	async mounted() {
		// Listen for the 'beforeunload' event on Page B
		 window.addEventListener('beforeunload', this.handleBeforeUnload);
		// await this.getSingleCourse();
		emitter.on('tabs', (isToggle: any) => {
			this.tabIndex = isToggle;
		});
	},
	beforeUnmount() {
    // Clean up the event listener when the component is about to be unmounted
   		 window.removeEventListener('beforeunload', this.handleBeforeUnload);
  		},
	async created() {
		await this.getSingleCourse();
		this.slideIndex = parseInt(this.$route.params.activeScreen) || 0;
		
		await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/getAll`).then((res: any) => {
			res.data.forEach((item:any)=>{if(!Object.keys(item.data).includes("isScreenActive")){item.data.isScreenActive = true}})
			var filteredScreens = res.data.filter((item:any)=>{return item.data.isScreenActive == true});
			this.slides = filteredScreens;
			this.slides.push({
				_id: '626aa23b0620124dec6c548s6',
				createdBy: '118101476679108383767',
				updatedBy: '118101476679108383767',
				createdAt: '2022-04-28T14:18:35.639Z',
				updatedAt: '2022-04-28T14:18:35.639Z',
				screenName: 'Screen',
				screenType: 'commonTemplate',
				order: '',
				screenStyles: {
					backgroundimage: '',
					color: 'red'
				},
				data: {}
			});
			this.slides.forEach((singleScreen: any) => {
				this.screenNames.push({ screenName: singleScreen.screenName, isActive: false });
			});
			this.screenNames[this.slideIndex].isActive = true;
		});
	}
});
