
import { defineComponent } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		apexchart: VueApexCharts
	},
	data(): any {
		return {
			allCourses: [],
			stackBarChart: [],
			selectedCourse: '',
			showGraph: false,
			series: [],
			stackedCategories: [],
			courseFeedBackInfo:[],
			chartOptions: {
				colors: ['#00B050', '#FFC000', '#4D4D4D', '#FF0000'],
				labels: ['Completed', 'In Progress', 'Not Started', 'Overdue'],
				legend: {
					fontSize: '14px',
					// horizontalAlign:'center',
					position: 'bottom'
				}
			},
			chartOptionsForStackedChart: {
				colors:["#FFC000","#FF0000","#4D4D4D","#00B050"],
				chart: {
					stacked: true,
					toolbar: {
						show: false
					},
					stackType: '100%'
				},
			plotOptions: {
					bar: {
						horizontal: true,
						barHeight:'20%',
					
					}	
				},
		
					
		
				yaxis: {
					labels: {
						show: true,
						minWidth: 10,
						maxWidth:350
					}  
				},
  
				xaxis: {
					//   type: 'category',
					categories: [],
					labels: {
						show: true
					}
				}
			},

			dashboardData: {}
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				res.data.map((object:any)=>{
                    if(Object.keys(object).includes('courseExpiry')){
                        this.allCourses.push(object);
                    }
                });
                // this.allCourses = res.data;
			});
		},
		async generateDashboardData() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/dashboardcoursesData`).then((res: any) => {
				this.dashboardData = res.data;
				console.log('ssss', this.dashboardData.status.inprogress);
			});
		},
		async generateFeedbackData() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/feedback`).then((res: any) => {
				this.courseFeedBackInfo = res.data;
			});
		},
		averageRating(object:any) {
			let ratingArray = [object.rating5, object.rating4, object.rating3, object.rating2, object.rating1];
			
			const sum = ratingArray.reduce(
				(acc, rating, index) => acc + rating * (5 - index),
				0
			);
			const totalRatings = ratingArray.reduce(
				(acc, rating) => acc + rating,
				0
			);

			if (totalRatings === 0) {
				return 0.0;
			} else {
				let averageRatingOfCourse = sum / totalRatings;
				return averageRatingOfCourse.toFixed(1);
			}
		},
		removeKeys(obj:any, keys:any){
			const newObj = { ...obj };
			keys.forEach((key:any) => {
				if(Object.keys(newObj).includes(key)){
					delete newObj[key];
				}
			});
			return newObj;
		},
		async generateAllCoursesCsv(){
			let payload = {
				courseId: this.selectedCourse
			};
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/exportallcoursesCsv`, payload).then((res: any) => {
				let csv = '';
				let items: any = [];
				let deleteKeys = ['modifiedAt','examStatus'];
				res.data.map((obj:any)=>{
					let a = this.removeKeys(obj, deleteKeys)
					items.push(a);
				})
				console.log('itemsitemsitemsitemsitemsitems', items);
				for (let row = 0; row < items.length; row++) {
					let keysAmount = Object.keys(items[row]).length;
					let keysCounter = 0;
					if (row === 0) {
						for (let key in items[row]) {
							if (key == 'statusCodeId') {
								csv += 'status' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else if (key == 'userid') {
								csv += 'userId' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else {
								csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							}
							keysCounter++;
						}
						keysCounter = 0;
					}
					for (let key in items[row]) {
						if (key == 'statusCodeId') {
							switch (items[row][key]) {
								case 10401:
									items[row][key] = 'Not Started';
									break;
								case 10402:
									items[row][key] = 'In Progress';
									break;
								case 10403:
									items[row][key] = 'Completed';
									break;
								case 10404:
									items[row][key] = 'Overdue';
									break;
								default:
									console.log(`error ${items[row][key]}.`);
							}
						}
						csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
						keysCounter++;
					}

					keysCounter = 0;
				}
				let link = document.createElement('a');
				link.id = 'download-csv';
				link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
				link.setAttribute('download', `DynematrixAllCourses.csv`);
				document.body.appendChild(link);
				link.click();
			});
		},
		async generateCsv() {
			console.log('selected course', this.selectedCourse);
			let payload = {
				courseId: this.selectedCourse
			};
			await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/exportCsv`, payload).then((res: any) => {
				let csv = '';
				let items: any = [];
				let deleteKeys = ['modifiedAt','examStatus'];
				res.data.map((obj:any)=>{
					let a = this.removeKeys(obj, deleteKeys)
					items.push(a);
				})
				
				// Loop the array of objects
				for (let row = 0; row < items.length; row++) {
					let keysAmount = Object.keys(items[row]).length;
					let keysCounter = 0;
					if (row === 0) {
						for (let key in items[row]) {
							if (key == 'statusCodeId') {
								csv += 'status' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else if (key == 'userid') {
								csv += 'userId' + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							} else {
								csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
							}
							keysCounter++;
						}
						keysCounter = 0;
					}
					for (let key in items[row]) {
						if (key == 'statusCodeId') {
							switch (items[row][key]) {
								case 10401:
									items[row][key] = 'Not Started';
									break;
								case 10402:
									items[row][key] = 'In Progress';
									break;
								case 10403:
									items[row][key] = 'Completed';
									break;
								case 10404:
									items[row][key] = 'Overdue';
									break;
								default:
									console.log(`error ${items[row][key]}.`);
							}
						}
						csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
						keysCounter++;
					}

					keysCounter = 0;
				}
				let link = document.createElement('a');
				link.id = 'download-csv';
				link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
				link.setAttribute('download', `Dynematrix${this.selectedCourse}.csv`);
				document.body.appendChild(link);
				link.click();
			});
		},
		async generateGraph() {
			console.log('selected course', this.selectedCourse);
			let payload = {
				courseId: this.selectedCourse
			};
			await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/dashboard`, payload).then((res: any) => {
				console.log('dahsboard data', res.data);
				let data = res.data[0];
				delete data._id;
				this.series = [];
				this.series.push(data['Completed'], data['In progress'], data['Not started'], data['Overdue']);
				// this.series = Object.values(data);
				console.log('this.series', this.series);
				this.showGraph = true;
			});
		},
		async generateStackedBar() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/generateStackBarChart`).then((res: any) => {
				let data = res.data;
				let Inprogress = [];
				let Notstarted = [];
				let Completed = [];
				let Overdue = [];
				let obj = [];

				for (var i = 0; i < data.length; i++) {
					console.log('chart', data[i]);
					this.stackedCategories.push(data[i]['_id']);
					console.log('stacked', this.stackedCategories);
					if (Object.keys(data[i].Inprogress)) {
						Inprogress.push(data[i].Inprogress);
					}
					if (Object.keys(data[i].Notstarted)) {
						Notstarted.push(data[i].Notstarted);
					}
					if (Object.keys(data[i].Completed)) {
						Completed.push(data[i].Completed);
					}
					if (Object.keys(data[i].Overdue)) {
						Overdue.push(data[i].Overdue);
					}
				}

				this.$refs.chart.updateOptions({
					xaxis: {
						categories: this.stackedCategories
					}
				});
				this.stackBarChart = [
					{
						name: 'In Progress',
						data: Inprogress
					},
					{
						name: 'Overdue',
						data: Overdue
					},
					{
						name: 'Not Started',
						data: Notstarted
					},
					{
						name: 'Completed',
						data: Completed
					}
				];
			});
		}
	},

	async mounted() {
		await this.getAllCourses();
		await this.generateDashboardData();
		await this.generateStackedBar();
		await this.generateFeedbackData();
	}
});
