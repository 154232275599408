
import { defineComponent } from 'vue';
import { toast } from '../../main';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			questionaire: [],
			updatescreen: false,
			newScreen: false
		};
	},
	validations(smIndex: any) {
		return {
			questionaire: {
				$each: helpers.forEach({
					screenName: { required },
					questionaireType: { required },
					question: { required }
				})
			}
		};
	},
	methods: {
		changedInputControlValue(question: any) {
			if (question._id) {
				question.updatescreen = true;
			} else {
				question.newScreen = true;
			}
		},
			resetData(question: any) {
				question.questionaireName= 'Question',
				question.questionaireType= '',
				question.question= '',
				question.options= [],
				question.createdBy= '',
				question.createdAt= '',
				question.updatedBy= '',
				question.updatedAt= '',
				question.validationScreenName= false,
				question.toggleSourceInput= false,
				question.answerVisibility= true
			
		},
		addquestions() {
			this.questionaire.push({
				questionaireName: 'Question',
				questionaireType: '',
				question: '',
				options: [],
				createdBy: '',
				createdAt: '',
				updatedBy: '',
				updatedAt: '',
				validationScreenName: false,
				toggleSourceInput: false,
				answerVisibility: true
			});
		},
		createPayload(moduleIndex: any) {
			var finalpayload;
			this.v$.$touch();
			var QuestionaireId = this.questionaire[moduleIndex]._id;
			this.questionaire[moduleIndex].validationScreenName = false;
			delete this.questionaire[moduleIndex].updatescreen;
			delete this.questionaire[moduleIndex].newScreen;
			this.questionaire[moduleIndex]['isActive'] =true;
			const uniqueValues = new Set(this.questionaire.map((v: any) => v.questionaireName));
			if (uniqueValues.size < this.questionaire.length) {
				this.questionaire[moduleIndex].validationScreenName = true;
			}
			let errorCondition = this.v$.questionaire.$each.$message[moduleIndex];
			// stop here if form is invalid
			if (errorCondition.length == 0 && !this.questionaire[moduleIndex].validationScreenName) {
				this.v$.$reset();
				delete this.questionaire[moduleIndex]['isActive'];
				delete this.questionaire[moduleIndex].optionsvalue;
				delete this.questionaire[moduleIndex].correctAnswer;
				delete this.questionaire[moduleIndex].optiondescription;
				delete this.questionaire[moduleIndex].answerVisibility;
				delete this.questionaire[moduleIndex].validationScreenName;
				delete this.questionaire[moduleIndex].toggleSourceInput;
				// delete this.questionaire[moduleIndex]._id

				let date = new Date();
				this.questionaire[moduleIndex].moduleId = this.$route.params.moduleId;
				finalpayload = [this.questionaire[moduleIndex]];

				if (this.questionaire[moduleIndex]._id == undefined) {
					this.saveQuestionaire(finalpayload,moduleIndex);
				} else {
					delete this.questionaire[moduleIndex]._id;
					this.updateQuestionaire(finalpayload, QuestionaireId);
				}
			}
		},
		backtocourseEdit() {
			this.$router.push({ name: 'edit-course', params: { id: this.$route.params.courseId } });
		},
		saveQuestionaire(finalpayload: any,moduleIndex:any) {
			this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/questionairescreens/add`, finalpayload)
				.then((response: any) => {
					// this.getallQuestionaireScreens();
					this.questionaire[moduleIndex]._id=response.data.upadtedQuestionId[0]
					toast.info('Questionaire Screen Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Questionaire Screen not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},

		updateQuestionaire(finalpayload: any, questionaireId: any) {
			var screenId = questionaireId;
			this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/questionairescreen/${screenId}/update`, finalpayload[0])
				.then((res: any) => {
					toast.info(' Questionaire Screen updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					this.getallQuestionaireScreens();
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		questionRadioScreen(moduleIndex: any): any {
			var visibility = this.questionaire[moduleIndex].correctAnswer;
			if (visibility == true) {
				this.questionaire[moduleIndex].answerVisibility = false;
			}
			this.questionaire[moduleIndex].options.push({
				text: this.questionaire[moduleIndex].optionsvalue,
				value: this.questionaire[moduleIndex].optionsvalue,
				description: this.questionaire[moduleIndex].optiondescription,
				correct: this.questionaire[moduleIndex].correctAnswer || false
			});
			this.questionaire[moduleIndex].optionsvalue = '';
			this.questionaire[moduleIndex].correctAnswer = '';
			this.questionaire[moduleIndex].optiondescription = '';
		},

		onradioDelete(optionIndex: any, moduleIndex: any) {
			if (this.questionaire[moduleIndex].options[optionIndex].correct == true) {
				this.questionaire[moduleIndex].answerVisibility = true;
			}
			this.questionaire[moduleIndex].options.splice(optionIndex, 1);
		},

		questioncheckBoxScreen(moduleIndex: any) {
			this.questionaire[moduleIndex].options.push({
				text: this.questionaire[moduleIndex].optionsvalue,
				value: this.questionaire[moduleIndex].optionsvalue,
				description: this.questionaire[moduleIndex].optiondescription,
				correct: this.questionaire[moduleIndex].correctAnswer || false
			});
			this.questionaire[moduleIndex].optionsvalue = '';
			this.questionaire[moduleIndex].correctAnswer = '';
			this.questionaire[moduleIndex].optiondescription = '';
		},
		oncheckBoxDelete(optionIndex: any, moduleIndex: any) {
			this.questionaire[moduleIndex].options.splice(optionIndex, 1);
		},

		getallQuestionaireScreens() {
			this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/questionairescreens/getAll`).then((res: any) => {
				this.spinnerLoading = false;
				this.questionaire = res.data;
			});
		}
	},
	mounted() {
		this.getallQuestionaireScreens();
	}
});
