
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
	props: ['control','type'],
	data(): any {
		return {
			currentPosition: '',
			totalDuration: '',
			duration: '',
			playerButton: false,
			toggleMute: false,
			togglePlay: false,
			progress: ''
		};
	},
    watch:{
        control:{
            handler(newVal){
             this.functionSwitch();
            },
            deep:true
        }
    },
	methods: {
		functionSwitch() {
			for (let key in this.control) {
				console.log("key",key)
				if(key == 'noSrc'){
					this.playerButton = false;
					this.toggleMute = false;
					this.$parent.$refs.player.muted = false;
					this.togglePlay = false;
					this.totalDuration='';
				}
				else if (key == 'start') {
					this.playerButton = false;
					this.toggleMute = false;
					this.$parent.$refs.player.muted = false;
					this.togglePlay = false;
					this.start(this.control[key]);
				} else if (key == 'time') {
					this.update(this.control[key]);
				} else if (key == 'ended'){
					this.playPause('ended');
				}
			}
		},
		mute() {
            if(this.type){
			    this.toggleMute == false ? ((this.toggleMute = true), (this.$parent.$refs.player.muted = true)) : ((this.toggleMute = false), (this.$parent.$refs.player.muted = false));
            }
            else{
               this.toggleMute == false ? (this.toggleMute = true, this.$parent.$refs.player.muted = true, this.playPause()) : (this.toggleMute = false, this.$parent.$refs.player.muted = false,this.playPause());
            }
		},
		playPause(status: any) {
                if (status == 'ended') {
                    this.$parent.$refs.player.pause(), (this.togglePlay = false);
                    this.type== false ? (this.$parent.$refs.player.muted = true,this.toggleMute = true):'';
                } else {
                    this.$parent.$refs.player.paused == false ? (this.$parent.$refs.player.pause(), (this.togglePlay = false)) : (this.$parent.$refs.player.play(), (this.togglePlay = true));
                }
		},
		start(e: any) {
            this.currentPosition = this.formatTime(e.srcElement.currentTime);
			this.totalDuration = this.formatTime(e.srcElement.duration);
			this.duration = e.srcElement.duration;
			this.progressPercentage(e.srcElement.currentTime, this.duration);
            this.type == false ? (this.toggleMute = true,this.$parent.$refs.player.pause()):'';
		},
		fullScreenToggle() {
			if (document.fullscreenElement) {
				document.exitFullscreen();
			} else {
				this.$parent.$refs.player.requestFullscreen();
			}
		},
		formatTime(sec: any) {
			var hours: any = Math.floor(sec / 3600);
			hours >= 1 ? (sec = sec - hours * 3600) : (hours = '00');
			var min: any = Math.floor(sec / 60);
			min >= 1 ? (sec = sec - min * 60) : (min = '00');
			sec < 1 ? (sec = '00') : void 0;
			sec = parseInt(sec);
			min.toString().length == 1 ? (min = '0' + min) : void 0;
			sec.toString().length == 1 ? (sec = '0' + sec) : void 0;
			if (hours == '00') return min + ':' + sec;
			else return hours + ':' + min + ':' + sec;
		},
		update(e: any) {
			this.currentPosition = this.formatTime(e.srcElement.currentTime);
			this.progressPercentage(e.srcElement.currentTime, this.duration);
		},
		progressPercentage(currentTime: any, duration: any) {
			var widthModified: any = ((currentTime / duration) * 100).toFixed(2);
			this.progress = { width: widthModified + '%' };
			((widthModified/100)*940 > 11)? (this.playerButton = true) : '';
		}
	}
});
