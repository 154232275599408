
import { defineComponent } from 'vue';
import { toast } from '../../main';
import { mapGetters } from 'vuex';

export default defineComponent({
	props: ['allClients'],
	data(): any {
		return {
			allCourses: [],
			teamsList: [],
			selected: [],
			isShow: false,
			isActive: false,
			clientActive: false,
			deleteCourseId: [],
			pageSize: 10,
			currentPage: 1,
			currentSort: 'name',
			currentSortDir: 'asc',
			activeCourses: [],
			clients: [
			],
			tenantsList: []
		};
	},
	methods: {
		async toggleAccordion(org:any){
			if(!org.isShow){
				org.activeCourses = await this.getClientAllCourses(org);
				this.getActivatedCourses(org.activeCourses,org.Courses)
			}
			org.isShow = !org.isShow;
		},
			async getAllTenants() {
				this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.tenantsList = res.data;
				this.tenantsList.forEach((organisation:any) => {
					// organisation.Courses = [... this.allCourses]
					organisation.Courses=JSON.parse(JSON.stringify(this.allCourses))
				});
			});
		},

		onActivate(singleCourse: any, singleclient: any) {
			setTimeout((): any => {
				if (singleCourse.isClientCourseActive) {
					var courseDetails = [];
					courseDetails.push({
						masterCourseId: singleCourse._id,
						courseName: singleCourse.courseName,
						assignedBy: this.userInfo.sub,
						assignedAt: new Date()
					});
					this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/course/${singleCourse._id}/assign/${singleclient.tenantName}`, courseDetails).then(async(response: any) => {
						if (response.status == 200) {
							singleclient.activeCourses = await this.getClientAllCourses(singleclient);
							toast.info('Updated', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
							this.createscreen = true;
						}
					});
				} else {
					for (var i = 0; i < singleclient.Courses.length; i++) {
						this.deleteCourseId = singleclient.activeCourses.filter((item: any) => item.masterCourseId == singleclient.Courses[i]['_id']);
						if (this.deleteCourseId.length >= 1 && singleCourse._id == this.deleteCourseId[0].masterCourseId) {
							this.$http.delete(`${process.env.VUE_APP_TRAINING_API_URL}/client/${singleclient._id}/course/${this.deleteCourseId[0]._id}`).then((response: any) => {
								if (response.status == 200) {
									toast.info('Updated', {
										timeout: 1000,
										closeOnClick: true,
										closeButton: 'button',
										icon: true
									});
									this.createscreen = true;
								}
							});
						}
					}
				}
			}, 10);
		},
		// nextPage: function () {
		// 	if (this.currentPage * this.pageSize < this.allCourses.length) this.currentPage++;
		// },
		// prevPage: function () {
		// 	if (this.currentPage > 1) this.currentPage--;
		// },
		// cantGoBack() {
		// 	return this.currentPage === 1;
		// },

		// cantGoForward() {
		// 	return this.allCourses.length / this.pageSize <= this.currentPage;
		// },

		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/courses/getAll`).then((res: any) => {
				this.allCourses = res.data.filter((course: any) => {
					return course.isActive;
				});
			});
		},
		async getClientAllCourses(org:any) {
			return await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${org._id}/courses`).then((res: any) => {
				return res.data;
			});
		},

	getActivatedCourses(activeCourses:any,allCourses:any) {
			for (var i = 0; i < allCourses.length; i++) {
				var result = activeCourses.filter((item: any) => item.masterCourseId == allCourses[i]['_id']);
				if (result.length >= 1) {
					allCourses[i].isClientCourseActive = true;
					this.clientActive = true;
				} else {
					allCourses[i].isClientCourseActive = false;
				}
			}
		}
	},
	computed: {
		sortedData: function (): any {
			return [...this.allCourses]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		...mapGetters({ userInfo: 'userInfo' })

	},
	async mounted() {
		await this.getAllCourses();
		await this.getAllTenants();
		// await this.getClientAllCourses();
		// await this.getActivatedCourses();
	}
});
